import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-self: stretch;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
`

const Section = styled.div`
  display: flex;
  flex: 1;
  gap: 1rem;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 50%;
  align-self: stretch;
  flex-grow: 1;
  
  @media (max-width: 1008px) {
    max-width: 100%;
  }
`

const Option = styled.div`
  display: flex;
  align-self: flex-start;
  margin-top: 2rem;
  margin-bottom: 2rem;
`

const Action = styled.div`
  display: flex;
  justify-content: center;
  margin: 2rem 0;
`

const DeleteAction = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 2rem 0;
`

const Table = styled.table`
  border-radius: 1rem;
  border-collapse: collapse;
  overflow: hidden;
  margin-top: 2rem;

  thead {
    font-size: 1.3rem;
    text-align: left;
  }

  thead th {
    font-weight: 400;
    color: #81858c;
    background: #f3f5f7;
    padding: 1.4rem 1.2rem;
  }

  th, td {
    padding: 1rem;
  }

  td {
    font-size: 1.4rem;
    font-weight: 350;
    color: #777777;
    border: 0;
    border-bottom: .1rem solid #efebef;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 1.2rem;
    cursor: pointer;
  }
`
const Article = styled.div`
  display: flex;
  align-items: center;
`

const Num = styled.div`
  font-size: 1.4rem;
`

const Subject = styled.div`
  padding: 1rem 0;
  font-size: 1.5rem;
  font-weight: 600;
  color: #999999;
`

const Order = styled.div`
  display: flex;
  flex-grow: 0;
  align-self: flex-start;
  margin-top: 1rem;
`

const Filter = styled.div`
  display: flex;
  flex-grow: 0;
  align-self: flex-start;
  margin-top: 1rem;
`

export const Style = {
  Container,
  Content,
  Section,
  Table,
  Action,
  DeleteAction,
  Article,
  Num,
  Subject,
  Option,
  Order,
  Filter
}
