import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: .2rem dashed #efebef;
  border-radius: 1rem;
  height: 14rem;
`

const Content = styled.div`
  display: flex;
  flex: 1;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  height: 100%;
  
  input {
    display: none;
  }
`

const Input = styled.input`
`

const Label = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  width: 100%;
  height: 100%;
  cursor: pointer;
  overflow: hidden;
  padding-top: 5rem;
`

const ImgContainer = styled.div<{ width?: string; height?: string; radius?: string }>`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: ${({width}) => (width ? width : '10rem')};
  height: ${({height}) => (height ? height : '10rem')};
  border-radius: ${({radius}) => (radius ? radius : '.4rem')};
  overflow: hidden;
`

const Img = styled.img`
  width: 100%;
  height: auto;
`

const Text = styled.div`
  border-radius: .8rem;
  font-size: 1.4rem;
  font-weight: 500;
  color: #888888;
  padding: 1rem;
`

export const Style = {
  Container,
  Content,
  Input,
  Label,
  ImgContainer,
  Img,
  Text
}
