import {useNavigate} from 'react-router-dom'
import {defaultService} from '../service'
import {loaderAtom} from '../../store/recoil/loader'
import {useSetRecoilState, useRecoilValueLoadable, useRecoilState, useRecoilValue} from 'recoil'
import {alertAtom} from '../recoil/alert'
import {bizAtom, settingAtom} from '../recoil/setting'
import {authAtom} from '../../store/recoil/auth'

export const SettingAction = () => {

  const loader = useSetRecoilState(loaderAtom)
  const setAlert = useSetRecoilState(alertAtom)
  const [biz, setBiz] = useRecoilState(bizAtom)
  const [setting, setSetting] = useRecoilState(settingAtom)
  const [auth, setAuth] = useRecoilState(authAtom)

  const getBiz = (params: any) => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'get',
      endPoint: '/setting/biz',
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })
        setBiz(response.data.payload)
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const updateBiz = (params: any) => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'post',
      endPoint: '/setting/biz',
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })
        setBiz(params)
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const getSetting = (params: any) => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'get',
      endPoint: '/admin/getSetting',
      params: ''
    }).then(
      response => {
        loader({
          isLoading: false
        })

        setSetting({
          ...setting,
          autoSales: response.data.payload.data[1].data.autoSales === '1' ? true : false,
          salesTerm: response.data.payload.data[1].data.calcDays,
          danal_card: response.data.payload.data[3].data.danal === '1' ? true : false,
          gal_card: response.data.payload.data[3].data.galaxiaCard === '1' ? true : false,
          gal_phone: response.data.payload.data[3].data.galaxiaMobi === '1' ? true : false,
          gal_bank: response.data.payload.data[3].data.galaxiaTransfer === '1' ? true : false,
          danal_card_percent: response.data.payload.data[2].data.danal,
          gal_card_percent: response.data.payload.data[2].data.galaxiaCard,
          gal_phone_percent: response.data.payload.data[2].data.galaxiaMobi,
          gal_bank_percent: response.data.payload.data[2].data.galaxiaTransfer
        })
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const updateSetting = (params: any) => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'post',
      endPoint: '/admin/setting',
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })
        setAuth({
          ...auth,
          name: params.adminName,
          phone: params.adminPhone,
          email: params.adminEmail
        })
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  return {
    getBiz,
    updateBiz,
    getSetting,
    updateSetting
  }
}
