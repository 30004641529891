import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  padding: 1rem;
  margin-top: 2rem;
`

const Button = styled.button`
  border: .1rem solid #efebef;
  padding: .7rem 1rem .3rem 1rem;
  border-radius: .4rem;
  cursor: pointer;

  @media (max-width: 768px) {
    padding: .5rem .8rem .2rem .8rem;
  }
  
  &.prev-btn {
  }

  &.next-btn {
    svg {
      transform: rotate(180deg);
    }
  }

  &.active {
    color: #ffffff;
    font-weight: 700;
    background: #000000;
  }
`

const Pages = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 .6rem;
`

const PageItem = styled.li`
  margin: 0 .4rem;
`


export const Style = {
  Container,
  Button,
  Pages,
  PageItem
}
