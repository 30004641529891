import {lazy, Suspense} from 'react'
import {Routes, Route, useNavigate} from 'react-router-dom'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
`

const Content = styled.div`
  display: flex;
  flex: 1;
`

const Index = () => {
  const List = lazy(() => import('./List'))

  return (
    <Container>
      <Content>
        <Suspense fallback={''}>
          <Routes>
            {['/', '/:pageNum'].map((path, index) => (
              <Route
                key={index}
                path={path}
                element={<List/>}
              />
            ))}
          </Routes>
        </Suspense>
      </Content>
    </Container>
  )
}

export default Index
