import React from 'react'
import styled from 'styled-components'
import {useRecoilValue} from 'recoil'
import {loaderAtom} from '../../store/recoil/loader'

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  z-index: 9;

  /***************
   바
   ****************/

  .progress {
    display: flex;
    flex: 1;
    position: absolute;
    top: 0;
    left: 0;
    background: transparent;
    width: 100%;
    height: .2rem;
    z-index: 9;
  }

  .progress:after {
    content: '';
    position: absolute;
    left: 0;
    width: 0;
    height: 100%;
    border-radius: 0rem;
    box-shadow: 0 0 5rem rgba(0, 0, 0, .2);
    animation: load 5s infinite;
  }

  @keyframes load {
    0% {
      width: 0;
      background: #53A8D1;
    }

    25% {
      width: 40%;
      background: #53A8D1;
    }

    50% {
      width: 60%;
      background: #53A8D1;
    }

    75% {
      width: 75%;
      background: #53A8D1;
    }

    100% {
      width: 100%;
      background: #53A8D1;
    }
  }

  /***************
   스피너
   ****************/

  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 4rem;
    height: 4rem;

    * {
      line-height: 0;
      box-sizing: border-box;
    }

    transform: translate(-50%, -50%);
  }

  .bounceStyle {
    background-color: #8878FB;
    animation-duration: 2.0s;
  }

  .double-bounce1,
  .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    opacity: .8;
    position: absolute;
    top: 0;
    left: 0;
    animation: double-bounce 2.0s ease-in-out infinite;
  }

  .double-bounce2 {
    animation-delay: -1.0s;
  }

  @keyframes double-bounce {
    0%, 100% {
      transform: scale(0.0);
    }
    50% {
      transform: scale(1.0);
    }
  }
`

const Index = () => {

  const loader = useRecoilValue(loaderAtom)

  return loader.isLoading ? (
    <Container>
      {
        loader.type === 'bar' || !loader.type ?
          <div className="progress"></div>
          : loader.type === 'spinner' &&
            <div className="spinner spinner--double-bounce">
              <div className="double-bounce1 bounceStyle"></div>
              <div className="double-bounce2 bounceStyle"></div>
            </div>
      }
    </Container>
  ) : null
}

export default Index
