import React, {useState, useEffect, useCallback} from 'react'

import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil'
import {PushAction} from '../../store/action'
import {authAtom} from '../../store/recoil/auth'
import {alertAtom} from '../../store/recoil/alert'
import {pushListAtom} from '../../store/recoil/push'

import Button from '../../module/button'
import Checkbox from '../../module/checkbox'

import moment from 'moment/moment'

import {Style} from './style/Style'
import Pagination from '../../module/pagination'
import {useNavigate, useParams} from 'react-router-dom'
import Select from '../../module/select'

const List = () => {

  const {pageNum} = useParams()
  const navigate = useNavigate()
  const setAlert = useSetRecoilState(alertAtom)
  const {getPushList} = PushAction()
  const [isCheckAll, setIsCheckAll] = useState<boolean>(false)
  const [isCheck, setIsCheck] = useState<any>([])
  const auth = useRecoilValue(authAtom)
  const pushList = useRecoilValue(pushListAtom)
  const [state, setState] = useState<any>({
    type: '',
    title: '',
    content: '',
    order: 0,
    submitted: false
  })
  const [currentPage, setCurrentPage] = useState<number>(0)
  const pageSize: number = 10

  const handleSelectAll = (e: any) => {
    let isCheckArray: any = []

    pushList.data.map((item, index) => (
      isCheckArray[index] = item.id
    ))

    setIsCheckAll(!isCheckAll)
    setIsCheck(isCheckArray)

    if (isCheckAll) {
      setIsCheck([])
    }
  }

  const handleSelect = (e: any) => {
    const {id, checked} = e.target

    setIsCheck((prevState: any) => {
      if (!checked) {
        return prevState.filter((item: any) => item !== Number(id))
      } else {
        return [...prevState, Number(id)]
      }
    })
  }

  useEffect(() => {
    getPushList({
      type: state.type,
      page: currentPage,
      size: pageSize
    })
  }, [currentPage])

  const deleteSelected = () => {
    if (isCheck.length < 1) {
      setAlert({
        title: '오류',
        type: 'alert',
        message: '선택된 항목이없습니다',
        isShow: true
      })
      return
    }

    setAlert({
      title: '확인',
      type: 'confirm',
      message: '삭제하시겠습니까?',
      isShow: true,
      action: {
        method: () => {
          // deletePolicy({ids: isCheck})
        }
      }
    })
  }

  const handleFilterChange = (e: any) => {
    setState({
      ...state,
      type: e.value
    })

    getPushList({
      type: e.value,
      page: 0,
      size: pageSize
    })

    setCurrentPage(0)
  }

  return (
    <Style.Container>
      <Style.Content>
        <Style.Filter>
          <Select
            id="type"
            name="type"
            title={'전체'}
            size={'lg'}
            config={{
              title: 'title',
              value: 'value'
            }}
            isSelected={0}
            value={[
              {title: '전체', value: ''},
              {title: '기프티', value: 1},
              {title: '고객센터', value: 2},
              {title: '판매', value: 3}]}
            onChange={(e) => handleFilterChange(e)}
            required={false}
            readOnly={false}
            disabled={false}
          />
        </Style.Filter>

        <Style.Table>
          <colgroup>
            {/*<col width="30"/>*/}
            <col width="40"/>
            <col width="100"/>
            <col width="80"/>
            <col/>
            <col width="90"/>
          </colgroup>
          <thead>
          <tr>
            {/*<th>*/}
            {/*  <Checkbox*/}
            {/*    id={'all'}*/}
            {/*    checked={isCheckAll}*/}
            {/*    onChange={(e) => handleSelectAll(e)}*/}
            {/*  />*/}
            {/*</th>*/}
            <th>No</th>
            <th>타입</th>
            <th>수신자</th>
            <th>내용</th>
            <th>발송일</th>
          </tr>
          </thead>
          <tbody>
          {pushList.data && pushList.data.map((item: any, index: number) => (
            <tr key={index}>
              {/*<td>*/}
              {/*  <Checkbox*/}
              {/*    id={`${item.id}`}*/}
              {/*    checked={isCheck.includes(item.id)}*/}
              {/*    onChange={(e) => handleSelect(e)}*/}
              {/*  />*/}
              {/*</td>*/}
              <td>
                <Style.Num>{(pushList.pageInfo.total - index) - ((currentPage) * pageSize)}</Style.Num>
              </td>
              <td>
                {item.name}
              </td>
              <td>
                {item.user === '.' ? '전체' : item.user}
              </td>
              <td>
                {item.content}
              </td>
              <td>
                <Style.Num>{moment(item.created_at).format('YYYY.MM.DD HH:mm:ss')}</Style.Num>
              </td>
            </tr>
          ))}
          </tbody>
        </Style.Table>

        {/*<Style.DeleteAction>*/}
        {/*  <Button*/}
        {/*    size={'md'}*/}
        {/*    margin={'1rem 0 0 0'}*/}
        {/*    radius={'.5rem'}*/}
        {/*    bgcolor={'#3f3b3f'}*/}
        {/*    color={'#ffffff'}*/}
        {/*    themecolor={'red'}*/}
        {/*    disabled={false}*/}
        {/*    onClick={() => deleteSelected()}*/}
        {/*  >*/}
        {/*    선택 삭제*/}
        {/*  </Button>*/}
        {/*</Style.DeleteAction>*/}

        <Pagination
          totalRecord={pushList.pageInfo.total}
          pageSize={pageSize}
          currentPage={currentPage}
          onChange={(page) => setCurrentPage(page)}
        />
      </Style.Content>
    </Style.Container>
  )
}

export default List
