import {atom} from 'recoil'
import moment from "moment";

interface CouponHistory {
  pageInfo: {
    page: number
    size: number
    total: number
  },
  data: {
    id: number
    name: string
    cnt: number
    value: number
  }[]
}

interface CouponList {
  pageInfo: {
    page: number
    size: number
    total: number
  },
  totalAmt: number,
  data: {
    id: number
    name: string
    cnt: number
    value: number
  }[]
}

interface CouponType {
  data: {
    id: number
    pin_pattern: string
    name: string
    cnt: number
    value: number
  }[]
}

export const couponHistoryAtom = atom<CouponHistory>({
  key: 'couponHistoryAtom',
  default: {
    data: [],
    pageInfo: {
      page: 0,
      size: 0,
      total: 0
    }
  }
})

export const couponListAtom = atom<CouponList>({
  key: 'couponListAtom',
  default: {
    data: [],
    pageInfo: {
      page: 0,
      size: 0,
      total: 0
    },
    totalAmt: 0
  }
})

export const couponTypeAtom = atom<CouponType>({
  key: 'couponTypeAtom',
  default: {
    data: []
  }
})


export const couponFilterStateAtom = atom<any>({
  key: 'couponFilterStateAtom',
  default: {
    from: moment().subtract(7, 'days'),
    to: moment(),
    searchType: 0
  }
})
