import React, {useState} from 'react'
import styled, {css} from 'styled-components'
import Svg from '../../module/svg'

/******************
 - 사용법

 <Select
   id="type"
   name="type"
   title={'선택'}
   size={'lg'}
   config={
      {
        title: 'title',
        value: 'value'
      }
    }
   isSelected={0}
   value={[
      {
        title: '일',
        value: 0
      },
      {
        title: '개월',
        value: 1
      }
    ]}
   onChange={(e)=> setFomula({
      ...fomula,
      type: e.title
    })}
   required={false}
   readOnly={false}
   disabled={false}
 />

 *******************/

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  // flex-grow: 0;
  align-items: center;
  position: relative;
  padding: .8rem 0 .8rem 0;
}
`

const Select = styled.div<{ $size?: string, $disabled?: boolean }>`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  flex-grow: 1;
  align-items: center;
  position: relative;
  min-width: 7rem;
  border-radius: .8rem;
  border: .1rem solid #efebef;
  color: #555555;
  background: ${({$disabled}) => ($disabled ? '#f9f9f9' : '#f8f8f8')};
  pointer-events: ${({$disabled}) => ($disabled ? 'none' : '')};
  align-self: stretch;
  padding: 1.4rem 0 1.4rem 0;
  
  // ${({$size}) => $size === 'lg' && css`
  //   height: 5.4rem;
  // `}
  //
  // ${({$size}) => $size === 'sm' && css`
  //   height: 4.6rem;
  // `}
`

const Label = styled.label`
  display: flex;
  flex: 1;
  font-size: 1.4rem;
  font-weight: 400;
  padding: 0 3.2rem 0 1.2rem;
  z-index: 1;
  white-space: nowrap;
`

const Icon = styled.span<{ $isToggle?: boolean }>`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: calc(50% - .7rem);
  right: 1rem;
  transform: rotate(-90deg);
  transition: 0.1s;
  opacity: .6;
  
  ${({$isToggle}) => $isToggle && css`
    opacity: 1;
    transform: rotate(90deg);
  `}
`

const Options = styled.ul`
  border: .1rem solid #efebef;
  background: #f8f8f8;
  overflow: hidden;
  margin-top: .4rem;
  border-radius: .8rem;
  position: absolute;
  top: 100%;
  z-index: 9;
  position: absolute !important;
  width: calc(100% + .2rem);
  margin-left: -.1rem;
  display: block !important;
  max-height: 20rem;
  overflow-y: auto;
`

const Option = styled.li`
  display: flex;
  flex: 1;
  z-index: 9;
  cursor: pointer;
  
  .active,
  :hover {
    background: #efebef;
    color: #555555;
  }
`

const OptionLabel = styled.label`
  display: flex;
  flex: 1;
  white-space: nowrap;
  padding: .6rem 1.2rem;
  font-size: 1.4rem;
  font-weight: 400;
`

interface SelectProps {
  id?: string
  name?: string
  title?: string
  size?: string
  value?: any
  config?: any
  isSelected: number
  readOnly?: boolean
  required?: boolean
  disabled?: boolean
  onChange?: (e: any) => void
}

const Index = ({
                 id,
                 name,
                 value,
                 title,
                 size,
                 config,
                 isSelected,
                 required,
                 disabled,
                 onChange
               }: SelectProps) => {

  const [state, setState] = useState<any>({
    isToggle: false,
    data: value,
    isSelect: '',
    editing: false
  })

  if (Array.isArray(value) && !state.isSelect && isSelected) {

    const isSelectItem = value.find(elem => `${elem[config.value]}` === `${isSelected}` ? elem[config.title] : '')

    if (isSelectItem) {
      setState({
        ...state,
        isSelect: isSelectItem ? isSelectItem[config['title']] : ''
      })
    }
  }

  const select = (item: any) => {
    setState({
      ...state,
      isSelect: item[config ? config.title : 'title'],
      isToggle: false,
    })
    onChange?.(item)
  }

  return (
    <Container>
      <Select
        $size={size}
        $disabled={false}
      >
        <Label
          onClick={() => setState({
            ...state,
            isToggle: !state.isToggle
          })}
        >
          {
            state.isSelect ? state.isSelect : title
          }
        </Label>

        <Icon $isToggle={state.isToggle}>
          <Svg
            icon="arrow"
            width={12}
            height={12}
            color="#666666"
          />
        </Icon>

        {
          state.isToggle ?
            <Options>
              {
                value ? value.map((item: any, index: number) => (
                  <Option
                    key={index}
                    onClick={(e) => select(item)}
                  >
                    <OptionLabel>
                      {
                        item[config ? config.title : 'title'] ?
                          item[config ? config.title : 'title'] : ''
                      }
                    </OptionLabel>
                  </Option>
                )) : ''
              }
            </Options>
            : ''
        }
      </Select>
    </Container>
  )
}

export default Index
