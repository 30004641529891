import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import styled from 'styled-components'

const Location = styled.div`
  ul {
    display: flex;

    li {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 1.2rem;
      font-weight: 300;

      &:not(:last-child):after {
        color: #999;
        content: ">";
        padding: 0 0.5rem;
      }

      &:last-child {
        font-weight: 400;
        color: #598deb;
      }
    }
  }
}
`

const Index = (props: any) => {

  const {location} = props

  return (
    <Location>
      <ul>
        <li>
          <Link to="/">홈</Link>
        </li>
        <li>{location.name}</li>
      </ul>
    </Location>
  )
}

export default Index
