import {atom} from 'recoil'


interface Setting {
  adminPass: string
  adminPassConfirm: string
  adminName: string
  adminEmail: string
  adminPhone: string
  autoSales: boolean
  salesTerm: string
  danal_card: boolean,
  gal_card: boolean,
  gal_phone: boolean,
  gal_bank: boolean,
  danal_card_percent: number
  gal_card_percent: number
  gal_phone_percent: number
  gal_bank_percent: number
}

export const bizAtom = atom({
  key: 'bizAtom',
  default: {
    bizName: '',
    bizAddress: '',
    generalLine: '',
    representName: '',
    bizRegisNumber: '',
    privacyManager: '',
    telecomSalesNumber: ''
  }
})

export const settingAtom = atom<Setting>({
  key: 'settingAtom',
  default: {
    adminPass: '',
    adminPassConfirm: '',
    adminName: '',
    adminEmail: '',
    adminPhone: '',
    autoSales: false,
    salesTerm: '',
    danal_card: false,
    gal_card: false,
    gal_phone: false,
    gal_bank: false,
    danal_card_percent: 0,
    gal_card_percent: 0,
    gal_phone_percent: 0,
    gal_bank_percent: 0,
  }
})
