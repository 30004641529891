import {defaultService} from "../service";
import {useRecoilState, useSetRecoilState} from 'recoil'
import {alertAtom} from "../recoil/alert";
import {loaderAtom} from "../recoil/loader";
import {pushListAtom} from "../recoil/push";

export const PushAction = () => {

  const setAlert = useSetRecoilState(alertAtom)
  const setLoader = useSetRecoilState(loaderAtom)
  const [pushList, setPushList] = useRecoilState(pushListAtom)

  return {
    sendMessage: (message: string) => {
      setLoader({isLoading: true})

      defaultService.handleService({
        endPoint: '/push',
        method: 'post',
        params: {message: message}
      })
        .then(
          response => {
            setAlert({
              title: '확인',
              type: 'alert',
              message: '발송되었습니다.',
              isShow: true
            })
            return
          },
          error => {
            setAlert({
              title: error.name,
              type: 'alert',
              message: error.message,
              isShow: true,
            })
          }
        )
        .finally(() => setLoader({isLoading: false}))
    },
    getPushList: (params: any) => {
      setLoader({isLoading: true})

      defaultService.handleService({
        endPoint: '/push/list',
        method: 'post',
        params: params
      })
        .then(
          response => {
            setPushList(response.data.payload)
          },
          error => {
            setAlert({
              title: error.name,
              type: 'alert',
              message: error.message,
              isShow: true,
            })
          }
        )
        .finally(() => setLoader({isLoading: false}))
    }
  }
}
