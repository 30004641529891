import {useNavigate} from 'react-router-dom'
import {defaultService} from '../service'
import {loaderAtom} from '../../store/recoil/loader'
import {useSetRecoilState, useRecoilValueLoadable, useRecoilState} from 'recoil'
import {alertAtom} from '../recoil/alert'
import {qnaListAtom, qnaAtom} from '../recoil/qna'

export const QnaAction = () => {

  const navigate = useNavigate()
  const loader = useSetRecoilState(loaderAtom)
  const setAlert = useSetRecoilState(alertAtom)
  const [qnaList, setQnaList] = useRecoilState(qnaListAtom)
  const setQna = useSetRecoilState(qnaAtom)

  const getQnaList = (params: any) => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'post',
      endPoint: '/inquiry/adminList',
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })
        setQnaList(response.data.payload)
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const getQnaListUpdate = (params: any) => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'post',
      endPoint: '/inquiry/adminInquiryUpdateList',
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })
        setQnaList(prevQnaList => ({
          ...prevQnaList,
          data: [
            ...prevQnaList.data,
            ...response.data.payload.data
          ],
          pageInfo: {
            ...prevQnaList.pageInfo,
            total: prevQnaList.pageInfo.total - params.size
          }
        }))
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const getQna = (params: any) => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'get',
      endPoint: `/inquiry/${params.id}`
    }).then(
      response => {
        loader({
          isLoading: false
        })
        setQna(response.data.payload)
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const regisAnswer = (params: any) => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'post',
      endPoint: '/inquiry/answer',
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })

        setAlert({
          title: '확인',
          type: 'alert',
          message: '등록되었습니다.',
          isShow: true
        })
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const deleteQna = (params: any) => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'post',
      endPoint: '/inquiry/delete',
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })

        const filteredList = qnaList.data.filter(item => !params.ids.includes(item.id))
        setQnaList({
          ...qnaList,
          data: filteredList
        })

        /**************
         * 삭제후 리스트 리셋
         * ***************/
        const smallItem = filteredList.reduce((prev, current) => {
          return prev.id < current.id ? prev : current
        }, filteredList[0])

        const smallId = smallItem.id

        getQnaListUpdate({
          id: smallId,
          page: params.currentPage,
          size: params.ids.length,
          open: 0
        })
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  return {
    getQnaList,
    getQna,
    regisAnswer,
    deleteQna
  }
}
