import React from 'react'
import {
  BrowserRouter,
  unstable_HistoryRouter as HistoryRouter,
  Route,
  Routes
} from 'react-router-dom'
import ScrollToTop from './ScrollToTop'

import {useRecoilState, useRecoilValue} from 'recoil'
import {authAtom} from '../store/recoil/auth'

/*************
 LayOut
 *************/

import DefaultLayout from '../layout/DefaultLayout'

/*************
 Auth
 *************/

import AuthRoute from './AuthRoute'

/*************
 public
 *************/

import Auth from '../page/auth'
import NotFound from '../page/NotFound'

/*************
 auth
 *************/

import Index from '../page/'
import Qna from '../page/qna/'
import Faq from '../page/faq/'
import Notice from '../page/notice/'
import User from '../page/user/'
import Setting from '../page/setting/'
import Business from '../page/business/'
import Policy from '../page/policy/'
import Push from '../page/push/'
import Brand from '../page/brand/'
import Product from '../page/product/'
import Account from '../page/account/'
import Sales from '../page/sales/'
import Coupon from '../page/coupon/'
import Event from '../page/event/'
import Purchase from '../page/purchase/'
import Pin from '../page/pin/'
import PinRegis from '../page/pin/Regis'
import PinJaego from '../page/pin/Jaego'

const Routers = () => {

  const auth = useRecoilValue(authAtom)

  const publicRoutes = [
    {name: 'Auth', path: '/auth/*', element: Auth},
    {name: '404', path: '/*', element: NotFound},
  ]

  const authRoutes = [
    {name: 'Index', path: '/', element: Index},
    {name: 'Qna', path: '/qna/*', element: Qna},
    {name: 'Faq', path: '/faq/*', element: Faq},
    {name: 'Notice', path: '/notice/*', element: Notice},
    {name: 'User', path: '/user/*', element: User},
    {name: 'Setting', path: '/setting/*', element: Setting},
    {name: 'Business', path: '/business/*', element: Business},
    {name: 'Policy', path: '/policy/*', element: Policy},
    {name: 'Push', path: '/push', element: Push},
    {name: 'Brand', path: '/brand/*', element: Brand},
    {name: 'Product', path: '/product/*', element: Product},
    {name: 'Account', path: '/account/*', element: Account},
    {name: 'Sales', path: '/sales/*', element: Sales},
    {name: 'Coupon', path: '/coupon/*', element: Coupon},
    {name: 'Event', path: '/event/*', element: Event},
    {name: 'Purchase', path: '/purchase/*', element: Purchase},
    {name: 'Pin', path: '/pin/*', element: Pin},
    {name: 'PinRegis', path: '/pin/regis', element: PinRegis},
    {name: 'PinJaego', path: '/pin/jaego/:id', element: PinJaego}
  ]

  return (
    <Routes>
      <Route
        path="/" element={
        <AuthRoute auth={auth}>
          <DefaultLayout />
        </AuthRoute>
      }>
        {authRoutes.map((route, key) => (
          <Route
            key={key}
            path={route.path}
            element={<route.element />}
          />
        ))}
      </Route>

      {publicRoutes.map((route, key) => (
        <Route
          key={key}
          path={route.path}
          element={<route.element/>}
        />
      ))}
    </Routes>
  )
}

export default Routers
