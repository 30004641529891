import {useNavigate} from 'react-router-dom'
import {defaultService} from '../service'
import {loaderAtom} from '../../store/recoil/loader'
import {useSetRecoilState, useRecoilValueLoadable, useRecoilState} from 'recoil'
import {alertAtom} from '../recoil/alert'
import {pinListAtom} from '../recoil/pin'
import {Simulate} from "react-dom/test-utils";
import load = Simulate.load;

export const PinAction = () => {

  const navigate = useNavigate()
  const loader = useSetRecoilState(loaderAtom)
  const setAlert = useSetRecoilState(alertAtom)
  const [pinList, setPintList] = useRecoilState(pinListAtom)
  const setPinList = useSetRecoilState(pinListAtom)

  const getVoucher = (params: any) => {
    loader({isLoading: true})
    defaultService.handleService({
      method: 'post',
      endPoint: '/pin/getVoucher',
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })
        setPinList(response.data.payload)
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const getPinList = (params: any) => {
    loader({isLoading: true})
    defaultService.handleService({
      method: 'post',
      endPoint: '/pin/getPinList',
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })
        setPinList(response.data.payload)
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const getPinInfo = async (params: any) => {
    loader({isLoading: true})
    return await defaultService.handleService({
      method: 'post',
      endPoint: '/pin/getPinInfo',
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })
        return response.data.payload
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const deletePin = (params: any) => {
    loader({isLoading: true})
    defaultService.handleService({
      method: 'post',
      endPoint: '/pin/deletePin',
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })

        const newPin = pinList.data.filter((item: any) => !params.ids.includes(item.id));

        setPinList({
          data: newPin,
          pageInfo: pinList.pageInfo
        })
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  return {
    getVoucher,
    getPinList,
    getPinInfo,
    deletePin
  }
}
