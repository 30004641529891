import React, {useState} from 'react'
import styled from 'styled-components'
import Regis from './Regis'
import List from './List'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
`

const Index = () => {
  return (
    <Container>
      <Regis />
      <List />
    </Container>
  )
}

export default Index
