import React from 'react'
import styled from 'styled-components'

const NotFoundError = styled.div`
  display: table;
  width: 100%;
  height: 100vh;
  text-align: center;
  background: #fcfcfc;

  .content {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    width: 100%;
    height: 100%;
    vertical-align: middle;

    h1 {
      font-family: sans-serif;
      font-weight: 900;
      font-size: 8rem;
      color: #666;
      display: inline-block;
    }
  }
`

const NotFound = () => {
  return(
    <NotFoundError>
      <div className="content">
        <h1>404</h1>
      </div>
    </NotFoundError>
  )
}

export default NotFound
