import React, {useRef} from 'react'
import {Outlet, useNavigate} from 'react-router-dom'

import Style from './default/style/'
import Header from './default/Header'
import Nav from './default/Nav'
import Footer from './default/Footer'
import Alert from '../module/alert/'
import Loader from '../module/loader/'

const DefaultLayout = () => {
  return (
    <>
      <Style/>
      <Header/>
      <main>
        <div className='nav'>
          <Nav/>
        </div>
        <div className="content">
          <div className="inner">
            <Outlet/>
          </div>
        </div>
      </main>
      <Footer/>
      <Alert/>
      <Loader/>
    </>
  )
}

export default DefaultLayout
