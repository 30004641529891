import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-self: stretch;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
`

const Title = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: 0;
`

const Description = styled.div`
  font-size: 1.2rem;
  font-weight: 600;
  color: #858585;
`

const Section = styled.div`
  display: flex;
  flex: 1;
  gap: 1rem;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 50%;
  align-self: stretch;
  flex-grow: 1;
  margin-bottom: 2rem;
  
  @media (max-width: 1008px) {
    max-width: 100%;
  }
`

const SectionColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  //gap: 1rem;
  //align-items: flex-start;
  //justify-content: flex-start;
  max-width: 50%;
  align-self: stretch;
  flex-grow: 1;
  margin-bottom: 2rem;
  
  @media (max-width: 1008px) {
    max-width: 100%;
  }
`

const Incomming = styled.div`
  display: flex;
  margin: 1.5rem 0 1rem 0;
  gap: 1rem;
`

const Option = styled.div`
  display: flex;
  margin: 1.5rem 0 2rem 0;
  gap: 1rem;
`

const Action = styled.div`
  display: flex;
  justify-content: center;
  margin: 2rem 0;
`

export const Style = {
  Container,
  Title,
  Description,
  Content,
  Section,
  SectionColumn,
  Incomming,
  Option,
  Action
}
