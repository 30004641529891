import {atom} from 'recoil'

interface PolicyList {
  pageInfo: {
    page: number
    size: number
    total: number
  },
  data: {
    id: number;
    title: string;
    content: string;
    order: number;
    createdAt: string;
    updatedAt: string;
    userId: number;
  }[]
}

interface Policy {
  id: number;
  title: string;
  content: string;
  order: number;
  createdAt: string;
  updatedAt: string;
  userId: number;
  type: number;
}

export const policyListAtom = atom<PolicyList>({
  key: 'policyListAtom',
  default: {
    data: [],
    pageInfo: {
      page: 0,
      size: 0,
      total: 0
    }
  }
})

export const policyAtom = atom<Policy>({
  key: 'policyAtom',
  default: {
    id: 0,
    title: '',
    content: '',
    order: 0,
    createdAt: '',
    updatedAt: '',
    userId: 0,
    type: 0
  }
})
