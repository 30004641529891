import React, {useState, useEffect, useCallback} from 'react'

import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil'
import {SettingAction} from '../../store/action'
import {authAtom} from '../../store/recoil/auth'
import {alertAtom} from '../../store/recoil/alert'
import {settingAtom} from '../../store/recoil/setting'

import InputField from '../../module/input'
import Button from '../../module/button'
import Location from '../../module/location'
import Checkbox from '../../module/checkbox'

import {Style} from './style/Style'

const Index = () => {

  const setAlert = useSetRecoilState(alertAtom)
  const {getSetting, updateSetting} = SettingAction()
  const [setting, setSetting] = useRecoilState(settingAtom)
  const auth = useRecoilValue(authAtom)
  const [state, setState] = useState<any>({
    adminPass: '',
    adminPassConfirm: '',
    adminName: '',
    adminEmail: '',
    adminPhone: '',
    autoSales: false,
    salesTerm: '',
    danal_card: false,
    gal_card: false,
    gal_phone: false,
    gal_bank: false,
    danal_card_percent: 0,
    gal_card_percent: 0,
    gal_phone_percent: 0,
    gal_bank_percent: 0,
    submitted: false
  })

  const handleChange = useCallback((e: any) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  }, [state])

  const handleSubmit = (e: any) => {
    if (e) {
      e.preventDefault()
    }

    const {
      adminPass,
      adminPassConfirm,
      adminName,
      adminEmail,
      adminPhone,
      salesTerm,
      danal_card_percent,
      gal_card_percent,
      gal_phone_percent,
      gal_bank_percent,
    } = state

    if((adminPass && adminPassConfirm) && adminPass !== adminPassConfirm) {
      setAlert({
        title: '확인',
        type: 'alert',
        message: `비밀번호를 확인하세요.`,
        isShow: true
      })
      return
    }

    if(!adminName || !adminEmail || !adminPhone) {
      setAlert({
        title: '확인',
        type: 'alert',
        message: '관리자 정보를 입력하세요.',
        isShow: true
      })
      return
    }

    if(!salesTerm) {
      setAlert({
        title: '확인',
        type: 'alert',
        message: '매입대금 정산시기를 입력하세요.',
        isShow: true
      })
      return
    }

    if(!danal_card_percent ||
      !gal_card_percent ||
      !gal_phone_percent ||
      !gal_bank_percent
    ) {
      setAlert({
        title: '확인',
        type: 'alert',
        message: '수수료 설정을 모두 입력하세요.',
        isShow: true
      })
      return
    }

    setState({
      ...state,
      submitted: true
    })

    updateSetting(state)
  }

  useEffect(() => {
    getSetting('')
  }, [])

  useEffect(() => {
    setState({
      ...state,
      adminId: auth.id,
      adminPass: '',
      adminPassConfirm: '',
      adminName: auth.name,
      adminEmail: auth.email,
      logged: auth.Logeed.toLocaleString(),
      adminPhone: auth.phone,
      autoSales: setting.autoSales,
      salesTerm: setting.salesTerm,
      danal_card: setting.danal_card,
      gal_card: setting.gal_card,
      gal_phone: setting.gal_phone,
      gal_bank: setting.gal_bank,
      danal_card_percent: setting.danal_card_percent,
      gal_card_percent: setting.gal_card_percent,
      gal_phone_percent: setting.gal_phone_percent,
      gal_bank_percent: setting.gal_bank_percent,
      submitted: false
    })
  }, [setting])

  return (
    <Style.Container>
      <Location location={{name: '환경 설정'}}/>
      <Style.Content>
        <Style.Title>관리자 비밀번호</Style.Title>
        <Style.Section>
          <InputField
            useAnimation={true}
            type={'password'}
            id="adminPass"
            name="adminPass"
            placeholder="비밀번호"
            value={state.adminPass}
            onChange={(e) => handleChange(e)}
            check={false}
          />

          <InputField
            useAnimation={true}
            type={'password'}
            id="adminPassConfirm"
            name="adminPassConfirm"
            placeholder="비밀번호 확인"
            value={state.adminPassConfirm}
            onChange={(e) => handleChange(e)}
            check={false}
          />
        </Style.Section>

        <Style.Title>관리자 정보</Style.Title>
        <Style.Section>
          <InputField
            useAnimation={true}
            type={'text'}
            id="adminName"
            name="adminName"
            placeholder="관리자 이름"
            value={state.adminName}
            onChange={(e) => handleChange(e)}
            check={false}
          />

          <InputField
            useAnimation={true}
            type={'text'}
            id="adminEmail"
            name="adminEmail"
            placeholder="이메일"
            value={state.adminEmail}
            onChange={(e) => handleChange(e)}
            check={false}
          />

          <InputField
            useAnimation={true}
            type={'text'}
            id="adminPhone"
            name="adminPhone"
            placeholder="휴대폰 번호"
            value={state.adminPhone}
            onChange={(e) => handleChange(e)}
            check={false}
          />

          <InputField
            useAnimation={true}
            type={'text'}
            id="adminPhone"
            name="adminPhone"
            placeholder="로그인 횟수"
            value={state.logged}
            onChange={(e) => handleChange(e)}
            check={false}
            disabled={true}
          />
        </Style.Section>

        <Style.Title>매입 설정</Style.Title>
        <Style.Incomming>
          <Checkbox
            id={'autoSales_on'}
            label={'자동매입 설정'}
            checked={state.autoSales ? true : false}
            onChange={(e) => setState({
              ...state,
              autoSales: !state.autoSales
            })}
          />

          <Checkbox
            id={'autoSales_off'}
            label={'자동매입 해제'}
            checked={!state.autoSales ? true : false}
            onChange={(e) => setState({
              ...state,
              autoSales: !state.autoSales
            })}
          />
        </Style.Incomming>

        <Style.SectionColumn>
          <InputField
            useAnimation={true}
            type={'text'}
            id="salesTerm"
            name="salesTerm"
            placeholder="매입대금 정산시기"
            value={state.salesTerm}
            onChange={(e) => handleChange(e)}
            check={false}
          />
          <Style.Description>관리자에서 설정한 시기 기준으로 판매대금이 정산됩니다. 0일시 즉시</Style.Description>
        </Style.SectionColumn>

        <Style.Title>결제 설정</Style.Title>
        <Style.Option>
          <Checkbox
            id={'danal_card'}
            label={'다날 카드결제'}
            checked={state.danal_card}
            onChange={(e) => setState({
              ...state,
              danal_card: !state.danal_card
            })}
          />

          <Checkbox
            id={'gal_card'}
            label={'갤럭시아 카드결제'}
            checked={state.gal_card}
            onChange={(e) => setState({
              ...state,
              gal_card: !state.gal_card
            })}
          />

          <Checkbox
            id={'gal_phone'}
            label={'갤럭시아 휴대폰'}
            checked={state.gal_phone}
            onChange={(e) => setState({
              ...state,
              gal_phone: !state.gal_phone
            })}
          />

          <Checkbox
            id={'gal_bank'}
            label={'갤럭시아 계좌이체'}
            checked={state.gal_bank}
            onChange={(e) => setState({
              ...state,
              gal_bank: !state.gal_bank
            })}
          />
        </Style.Option>

        <Style.Title>수수료 설정 (%)</Style.Title>
        <Style.Section>
          <InputField
            useAnimation={true}
            type={'text'}
            id="danal_card_percent"
            name="danal_card_percent"
            placeholder="다날 카드결제"
            value={state.danal_card_percent}
            onChange={(e) => handleChange(e)}
            check={false}
          />
          <InputField
            useAnimation={true}
            type={'text'}
            id="gal_card_percent"
            name="gal_card_percent"
            placeholder="갤럭시아 카드결제"
            value={state.gal_card_percent}
            onChange={(e) => handleChange(e)}
            check={false}
          />
          <InputField
            useAnimation={true}
            type={'text'}
            id="gal_phone_percent"
            name="gal_phone_percent"
            placeholder="갤럭시아 휴대폰 결제"
            value={state.gal_phone_percent}
            onChange={(e) => handleChange(e)}
            check={false}
          />
          <InputField
            useAnimation={true}
            type={'text'}
            id="gal_bank_percent"
            name="gal_bank_percent"
            placeholder="갤럭시아 계좌이체"
            value={state.gal_bank_percent}
            onChange={(e) => handleChange(e)}
            check={false}
          />
        </Style.Section>

        <Style.Action>
          <Button
            size={'md'}
            margin={'1rem 1rem 1rem 0'}
            padding={'1rem 2rem'}
            radius={'.5rem'}
            bgcolor={'#3f3b3f'}
            color={'#ffffff'}
            fontSize={'1.4rem'}
            themecolor={'dark-gray'}
            disabled={false}
            onClick={(e) =>
              setAlert({
                title: '확인',
                type: 'confirm',
                message: '수정하시겠습니까?',
                isShow: true,
                action: {
                  method: () => {
                    handleSubmit(e)
                  }
                }
              })}
          >
            {state.bizName ? '수정' : '등록'}
          </Button>
        </Style.Action>
      </Style.Content>
    </Style.Container>
  )
}

export default Index
