import {defaultService} from "../service";
import {useSetRecoilState} from "recoil";
import {loaderAtom} from "../recoil/loader";
import {alertAtom} from "../recoil/alert";

export interface dashboardInterface {
  statistics: {
    all_buy: string
    week_buy: string
    month_buy: string
    all_sale: string
    week_sale: string
    month_sale: string
    all_profit: string
    week_profit: string
    month_profit: string
  },
  notice: []
}

export const AdminAction = () => {

  const loader = useSetRecoilState(loaderAtom)
  const setAlert = useSetRecoilState(alertAtom)

  return {
    getDashBoard: (setState: any) => {
      loader({isLoading: true})
      defaultService.handleService({
        endPoint: '/admin/dashboard',
        method: 'get'
      }).then(
        response => setState(response.data.payload),
        error => {
          setAlert({
            title: error.name,
            type: 'alert',
            message: error.message,
            isShow: true
          })
        }
      ).finally(() => loader({isLoading: false}))
    }
  }
}
