import {useNavigate} from 'react-router-dom'
import {defaultService} from '../service'
import {loaderAtom} from '../../store/recoil/loader'
import {useSetRecoilState, useRecoilValueLoadable, useRecoilState} from 'recoil'
import {alertAtom} from '../recoil/alert'
import {purchaseListAtom} from '../recoil/purchase'
import moment from 'moment'

export const PurchaseAction = () => {
  const navigate = useNavigate()
  const loader = useSetRecoilState(loaderAtom)
  const setAlert = useSetRecoilState(alertAtom)
  const [purchaseList, setPurchaseList] = useRecoilState(purchaseListAtom)

  const getPurchaseList = (params: any) => {
    loader({isLoading: true})
    defaultService.handleService({
      method: 'post',
      endPoint: '/purchase/list',
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })
        setPurchaseList(response.data.payload)
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const updatePurchase = (params: any) => {
    loader({isLoading: true})
    defaultService.handleService({
      method: 'post',
      endPoint: '/purchase/update',
      params: params
    }).then(
      response => {

        setAlert({
          title: '확인',
          type: 'alert',
          message: '처리되었습니다',
          isShow: true
        })
        loader({
          isLoading: false
        })

        for(let i=0;i<params.ids;i++){
          let idx = purchaseList.data.findIndex(item => item.id === params.ids[i])
          
          idx>=0 && setPurchaseList({
            ...purchaseList,
            data: [
              ...purchaseList.data.slice(0, idx),
              {
                ...purchaseList.data[idx],
                status: params.status,
                reject_reason: params.reject_reason,
                completed_at: params.status === '매입완료' ? moment(new Date()).format('YYYY.MM.DD') : ''
              },
              ...purchaseList.data.slice(idx+1, purchaseList.data.length)
            ]
          })
        }
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }
  return {
    getPurchaseList,
    updatePurchase,
  }
}
