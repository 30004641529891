import {atom} from 'recoil'

interface PinList {
  pageInfo: {
    page: number
    size: number
    total: number
  },
  data: {
    id: number;
    brand: string;
    original_price: number;
    sale_price: number | null;
  }[]
}

export const pinListAtom = atom<PinList>({
  key: 'pinListAtom',
  default: {
    data: [],
    pageInfo: {
      page: 0,
      size: 0,
      total: 0
    }
  }
})
