import {atom} from 'recoil'

interface UserList {
  pageInfo: {
    page: number
    size: number
    total: number
  },
  data: {
    id: number
    title: string
    order: number
    content: string
    open: number
    createdAt: string
    updatedAt: string
    userId: number
  }[]
}

interface User {
  Logeed: number;
  accCreatedAt: null;
  accInfo: null;
  accept: number;
  access: number;
  avatar: null;
  birth: string;
  cash: number;
  createdAt: string;
  email: string;
  gender: string;
  id: number;
  isAlert: number;
  isBadge: number;
  isOrder: number;
  isbenefit: number;
  ischarging: number;
  ispayment: number;
  isreport: number;
  name: string;
  password: string;
  phone: string;
  role: number;
  salt: string;
  save_price: number;
  seller_money:number;
  token: string;
  total_cash: number;
  total_price: number;
  type: string;
  updatedAt: string;
  bank: string | null;
  bank_number: string | null;
  bank_user: string | null;
  bank_date: string | null;
}

export interface userPointHistoryInterface {
  count: number,
  result: any[]
}

export const userListAtom = atom<UserList>({
  key: 'userListAtom',
  default: {
    data: [],
    pageInfo: {
      page: 0,
      size: 0,
      total: 0
    }
  }
})

export const userAtom = atom<User>({
  key: 'userAtom',
  default: {
    Logeed: 0,
    seller_money: 0,
    accCreatedAt: null,
    accInfo: null,
    accept: 0,
    access: 0,
    avatar: null,
    birth: '',
    cash: 0,
    createdAt: '',
    email: '',
    gender: '',
    id: 0,
    isAlert: 0,
    isBadge: 0,
    isOrder: 0,
    isbenefit: 0,
    ischarging: 0,
    ispayment: 0,
    isreport: 0,
    name: '',
    password: '',
    phone: '',
    role: 0,
    salt: '',
    save_price: 0,
    token: '',
    total_cash: 0,
    total_price: 0,
    type: '',
    updatedAt: '',
    bank: '',
    bank_number: '',
    bank_user: '',
    bank_date: '',
  }
})

export const userPointHistoryAtom = atom<userPointHistoryInterface>({
  key: 'userPointHistoryAtom',
  default: {
    count: 0,
    result: []
  }
})

export const userSellerMoneyHistoryAtom = atom<userPointHistoryInterface>({
  key: 'userSellerMoneyHistoryAtom',
  default: {
    count: 0,
    result: []
  }
})
