import React, {useState, useEffect} from 'react'
import {Style} from './Style'
import Svg from '../svg'
import * as XLSX from 'xlsx'

const File = (props) => {
  const [state, setState] = useState({
    name: '',
    thumbnail: ''
  })

  const handleDragOver = (e) => {
    e.preventDefault()
  }

  const handleDrop = (e) => {
    e.preventDefault()
    const file = e.dataTransfer.files[0]
    setFile(null, file)
  }

  const setFile = (e, file) => {
    if (file) {

      const reader = new FileReader();
      reader.onload = (e) => {
        if (!e.target?.result) return;
        const workbook = XLSX.read(reader.result, {type: 'array'})
        const worksheet = workbook.Sheets[workbook.SheetNames[0]]
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
        const dataWithoutHeader = jsonData.slice(1);

        setState({
          ...state,
          name: file.name,
          thumbnail: reader.result
        })
        props.onChange({
          file: file,
          thumb: reader.result,
          jsonData: dataWithoutHeader
        })
      };
      reader.readAsArrayBuffer(file)

      if(e) {
        e.target.value = ''
      }
    }
  }

  useEffect(() => {
    setState({
      ...state,
      thumbnail: props.thumbnail
    })
  }, [props.thumbnail])

  return (
    <Style.Container
        onDragOver={handleDragOver}
        onDrop={handleDrop}
    >
      <Style.Content>
        <Style.Input
            type="file"
            id={props.name}
            name={props.name}
            placeholder={props.placeholder}
            accept={props.accept}
            onChange={(e) => setFile(e, e.target.files[0])}
        />
        <Svg
            icon={'upload'}
            width={25}
            height={25}
            fill={'#45B058'}
            color={'#45B058'}
        />
        <Style.Label htmlFor={props.name}>
          {state.thumbnail && props.isTransform ? (
            <Style.ImgContainer
              width={props.width}
              height={props.height}
              radius={props.radius}
            >
              <Style.Img
                src={state.thumbnail}
                alt="thumbnail"
              />
            </Style.ImgContainer>
          ) : (
            <Style.Text>
              {props.placeholder}
            </Style.Text>
          )}
        </Style.Label>
      </Style.Content>
    </Style.Container>
  )
}

export default File
