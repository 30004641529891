import React, {useEffect, lazy, Suspense} from 'react'
import {Route, Routes} from 'react-router-dom'
import {useNavigate} from 'react-router-dom'
import {SocialAction} from '../../store/action/'

const Naver = () => {

  const navigate = useNavigate()
  const {checkUser} = SocialAction()

  // const hash = new URL(window.location.href).searchParams.get('code')
  // const {getNaverToken} = SocialAction()

  // const initNaverLogin = () => {
  //   if (!hash) {
  //     window.location.href = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${process.env.REACT_APP_NAVER_CLIENT_ID}&client_secret=${process.env.REACT_APP_NAVER_CLIENT_SECRET}&state=${false}&redirect_uri=${process.env.REACT_APP_NAVER_CALLBACK_URL}`
  //   } else {
  //     getNaverToken(hash)
  //   }
  // }

  const hash = new URL(window.location.href).hash

  const initNaverLogin = () => {

    const naverLogin = new window.naver.LoginWithNaverId({
      clientId: process.env.REACT_APP_NAVER_CLIENT_ID,
      callbackUrl: process.env.REACT_APP_NAVER_CALLBACK_URL,
      isPopup: false,
      loginButton: {color: 'white', type: 1, height: '27', border: 0},
    })

    naverLogin.init()

    try {
      naverLogin.getLoginStatus((status: any) => {
        if (status) {
          checkUser({
            provider: 'naver',
            user: naverLogin.user
          })
        }
      })
    } catch (error) {
      console.log(error)
    }

    if (!hash) {
      const loginBtn: any = document.getElementById('naverIdLogin')?.firstChild
      loginBtn.click()
    }
  }

  useEffect(() => {
    initNaverLogin()
  }, [])

  return (
    <>
      <div
        id={'naverIdLogin'}
        style={{display: 'none'}}
      ></div>
      <Suspense fallback={''}>
        <Routes>
          <Route
            path="/"
            element={
              <div
                id={'naverIdLogin'}
              ></div>
            }
          />
          {/*
            // 결과화면 출력할때 사용..
            <Route
              path="/naver"
              element={<naver/>}
            />
          */}
        </Routes>
      </Suspense>
    </>
  )

}

export default Naver
