import {useNavigate} from 'react-router-dom'
import {defaultService} from '../service'
import {loaderAtom} from '../../store/recoil/loader'
import {useSetRecoilState, useRecoilValueLoadable, useRecoilState} from 'recoil'
import {alertAtom} from '../recoil/alert'
import {categoryAtom, brandListAtom, brandAtom} from '../recoil/brand'

export const BrandAction = () => {

  const navigate = useNavigate()
  const loader = useSetRecoilState(loaderAtom)
  const setAlert = useSetRecoilState(alertAtom)
  const [brandList, setBrandList] = useRecoilState(brandListAtom)
  const setCategory = useSetRecoilState(categoryAtom)
  const setBrand = useSetRecoilState(brandAtom)

  const getCategory = () => {
    loader({isLoading: true})
    defaultService.handleService({
      method: 'get',
      endPoint: '/list/category'
    }).then(
      response => {
        loader({
          isLoading: false
        })
        setCategory(response.data.payload)
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const getBrandList = (params: any) => {
    loader({isLoading: true})
    defaultService.handleService({
      method: 'post',
      endPoint: '/brand/list',
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })
        setBrandList(response.data.payload)
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const getBrand = (params: any) => {
    loader({isLoading: true})
    defaultService.handleService({
      method: 'post',
      endPoint: `/brand/detail/`,
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })
        setBrand(response.data.payload)
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const regisBrand = (params: any) => {
    console.log('regis')

    loader({
      isLoading: true
    })

    const formData = new FormData()
    formData.append('image', params.image)
    formData.append('title', params.title)
    formData.append('order', params.order)
    formData.append('ppL', params.ppL ? '1': '0')
    formData.append('userId', params.userId)
    formData.append('categoryId', params.categoryId)
    formData.append('high_percent', params.high_percent)
    formData.append('low_percent', params.low_percent)
    formData.append('sale_percent', params.sale_percent)
    formData.append('sale_count', params.sale_count)
    formData.append('is_gift',params.is_gift ? '1' : '0')

    defaultService.handleService({
      method: 'post',
      endPoint: '/brand/add',
      params: formData
      }).then(
      response => {
        loader({
          isLoading: false
        })
        navigate('/brand')
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.response.data.message.title,
          type: 'alert',
          message: error.response.data.message.message,
          isShow: true
        })
      }
    )
  }

  const updateBrand = (params: any, id: any) => {

    loader({
      isLoading: true
    })

    const high = Number(params.high_percent / 100).toString()
    const low = Number(params.low_percent / 100).toString()
    const sale = Number(params.sale_percent / 100).toString()

    const formData = new FormData()
    formData.append('id', id)
    formData.append('image', params.image)
    formData.append('title', params.title)
    formData.append('order', params.order)
    formData.append('ppL', params.ppL ? '1': '0')
    formData.append('userId', params.userId)
    formData.append('categoryId', params.categoryId)
    formData.append('high_percent', high)
    formData.append('low_percent', low)
    formData.append('sale_percent', sale)
    formData.append('sale_count', params.sale_count)
    formData.append('is_gift',params.is_gift ? '1' : '0')
    formData.append('pay_type', params.pay_type)

    defaultService.handleService({
      method: 'post',
      endPoint: '/brand/update',
      params: formData,
      }).then(
      response => {
        loader({
          isLoading: false
        })
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const deleteBrand = (params: any) => {
    loader({isLoading: true})
    defaultService.handleService({
      method: 'post',
      endPoint: '/brand/delete',
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })

        const filteredList = brandList.data.filter(item => !params.ids.includes(item.id))
        setBrandList({
          ...brandList,
          data: filteredList
        })
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  return {
    getCategory,
    getBrandList,
    getBrand,
    regisBrand,
    updateBrand,
    deleteBrand
  }
}
