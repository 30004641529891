import React, { useState, useEffect, useRef } from 'react'
import Location from '../module/location'
import {useNavigate} from 'react-router-dom'

import styled from 'styled-components'
import {AdminAction, dashboardInterface} from "../store/action/admin";
import moment from 'moment'
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  gap: 1rem;
`

const Content = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
  gap: 2rem;
  margin-top: 1rem;
  
  @media (max-width: 1000px) {
    flex-direction: column;
  }
`

const Section = styled.div`
  display: flex;
  flex: 1;
  border: 0.1rem solid #efebef;
  border-radius: 2rem;
  padding: 1.5rem;
  background: #f9f9f9;
  
  &:first-child {
    min-width: 37%;
    background: #ffffff;
  }
`

const Title = styled.div`
  display: flex;
  justify-content: flex-start;
  font-size: 1.6rem;
  font-weight: 600;
`

const Notice = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
`

const List = styled.ul`
  display: flex;
  flex: 1;
  align-self: stretch;
  flex-direction: column;
  margin-top: 1.5rem;
`

const ListItem = styled.li`
  display: flex;
  justify-content: space-between;
  margin: .8rem 0 0 0;
  font-size: 1.5rem;
  font-weight: 500;
  cursor: pointer;
`

const Date = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 0 1rem;
  font-size: 1.4rem;
  font-weight: 500;
`

const Calc = styled.div`
  display: flex;
  flex: 1;
  align-self: flex-start;
  flex-direction: column;
`

const Num = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 4.6rem;
  font-weight: 900;
  color: #e80736;
  margin: 1.4rem 0 1.4rem 0;
  border-bottom: .1rem dashed #afafaf;
`

const Term = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
`

const TermDate = styled.div`
  font-size: 1.6rem;
  font-weight: 500;
`

const TermNum = styled.div`
  font-size: 2.6rem;
  font-weight: 500;
`

const initState: dashboardInterface = {
  statistics: {
    all_buy: '0',
    week_buy: '0',
    month_buy: '0',
    all_sale: '0',
    week_sale: '0',
    month_sale: '0',
    all_profit: '0',
    week_profit: '0',
    month_profit: '0',
  },
  notice: []
}

const Index = () => {

  const [state, setState] = useState<dashboardInterface>(initState)
  const {getDashBoard} = AdminAction()
  const navigate = useNavigate()

  useEffect(() => {
    getDashBoard(setState)
  }, [])

  return (
    <Container>
      <Location location={{name: 'DashBoard'}}/>
      <Content>
        <Section>
          <Notice>
            <Title onClick={() => navigate('/notice')}>공지사항</Title>
            <List>
              {state.notice.map((item:any) => {
                return (<ListItem key={item.id} onClick={() => navigate('/notice/regis/' + item.id)}>{item.title}<Date>{moment(item.updatedAt).format('YYYY.MM.DD')}</Date></ListItem>)
              })}
            </List>
          </Notice>
        </Section>
        <Section>
          <Calc>
            <Title>총 구매금액</Title>
            <Num>{Number(state.statistics.all_buy).toLocaleString('ko-KR')}</Num>
            <Term><TermDate>1주</TermDate> <TermNum>{Number(state.statistics.week_buy).toLocaleString('ko-KR')}</TermNum></Term>
            <Term><TermDate>30일</TermDate> <TermNum>{Number(state.statistics.month_buy).toLocaleString('ko-KR')}</TermNum></Term>
          </Calc>
        </Section>
        <Section>
          <Calc>
            <Title>총 판매금액</Title>
            <Num>{Number(state.statistics.all_sale).toLocaleString('ko-KR')}</Num>
            <Term><TermDate>1주</TermDate> <TermNum>{Number(state.statistics.week_sale).toLocaleString('ko-KR')}</TermNum></Term>
            <Term><TermDate>30일</TermDate> <TermNum>{Number(state.statistics.month_sale).toLocaleString('ko-KR')}</TermNum></Term>
          </Calc>
        </Section>
        <Section>
          <Calc>
            <Title>순수익</Title>
            <Num>{Number(state.statistics.all_profit).toLocaleString('ko-KR')}</Num>
            <Term><TermDate>1주</TermDate> <TermNum>{Number(state.statistics.week_profit).toLocaleString('ko-KR')}</TermNum></Term>
            <Term><TermDate>30일</TermDate> <TermNum>{Number(state.statistics.month_profit).toLocaleString('ko-KR')}</TermNum></Term>
          </Calc>
        </Section>
      </Content>

    </Container>
  )
}

export default Index
