import {atom} from 'recoil'

interface LoaderProps {
  type?: string;
  isLoading: boolean;
}

export const loaderAtom = atom<LoaderProps>({
  key: 'loaderAtom',
  default: {
    type: 'bar',
    isLoading: true
  }
})
