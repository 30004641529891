import {useNavigate} from 'react-router-dom'
import {defaultService} from '../service'
import {loaderAtom} from '../../store/recoil/loader'
import {useSetRecoilState, useRecoilValueLoadable, useRecoilState} from 'recoil'
import {alertAtom} from '../recoil/alert'
import {couponHistoryAtom, couponListAtom, couponTypeAtom} from '../recoil/coupon'
import moment from 'moment'

export const CouponAction = () => {

  const navigate = useNavigate()
  const loader = useSetRecoilState(loaderAtom)
  const setAlert = useSetRecoilState(alertAtom)
  const [couponHistory, setCouponHistory] = useRecoilState(couponHistoryAtom)
  const [couponList, setCouponList] = useRecoilState(couponListAtom)
  const [couponType, setCouponType] = useRecoilState(couponTypeAtom)

  const create = (params: any, cb?: any) => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'post',
      endPoint: '/coupon/create',
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })
        setCouponHistory({
          ...couponHistory,
          data: [
            {
              ...params,
              id: response.data.id,
              createdAt: moment().format('YYYY.MM.DD')
            },
            ...couponHistory.data
          ]
        })
        cb && cb({id: response.data.payload.id})
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const getCouponCreated = (params: any) => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'post',
      endPoint: '/coupon/getCouponCreated',
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })
        setCouponHistory(response.data.payload)
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const getCouponList = (params: any) => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'post',
      endPoint: '/coupon/getCouponList',
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })
        setCouponList(response.data.payload)
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const getCouponType = () => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'post',
      endPoint: '/coupon/getCouponType',
    }).then(
      response => {
        loader({
          isLoading: false
        })
        setCouponType(response.data.payload)
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const downloadExcel = (params: any) => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'post',
      endPoint: '/coupon/downloadExcel',
      responseType: 'blob',
      params: params
    }).then(
      response => {

        loader({
          isLoading: false
        })

        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'coupons.xlsx')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }
  const cancelCoupons = (params : any, cb?: any) => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'post',
      endPoint: '/coupon/cancelCoupons',
      params: params
    }).then(
      response => {
        console.log(params)
        cb && cb(params)
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const deleteCreated = (params : any, cb?: any) => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'post',
      endPoint: '/coupon/deleteCoupons',
      params: params
    }).then(
      response => {

        // loader({
        //   isLoading: false
        // })
        // const filteredList = couponHistory.data.filter(item => !params.ids.includes(item.id))
        // setCouponHistory({
        //   ...couponHistory,
        //   data: filteredList
        // })

        cb && cb(params)

      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  return {
    create,
    getCouponCreated,
    getCouponList,
    getCouponType,
    downloadExcel,
    deleteCreated,
    cancelCoupons
  }
}
