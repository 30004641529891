import {useNavigate} from 'react-router-dom'
import {defaultService} from '../service'
import {loaderAtom} from '../../store/recoil/loader'
import {useSetRecoilState, useRecoilValueLoadable, useRecoilState} from 'recoil'
import {alertAtom} from '../recoil/alert'
import {accountListAtom} from '../recoil/account'

export const AccountAction = () =>{
  const navigate = useNavigate()
  const loader = useSetRecoilState(loaderAtom)
  const setAlert = useSetRecoilState(alertAtom)
  const [accountList, setAccountList] = useRecoilState(accountListAtom)

  const getAccountList = (params: any) => {
    console.log('accountAction... ')
    loader({isLoading: true})
    defaultService.handleService({
      method: 'post',
      endPoint: '/payment/list',
      params: params
    }).then(
      response => {
        console.log('here: ', response.data.payload)
        loader({
          isLoading: false
        })
        setAccountList(response.data.payload)
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }
  const setComplete = (params: any) => {
    loader({isLoading: true})
    defaultService.handleService({
      method: 'post',
      endPoint: '/payment/setComplete',
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })

        const updated = accountList.data
          .filter(item => params.ids.includes(item.id) )
          .map(item => {
            return {
              ...item,
              status: "처리완료"
            }
          })
        const sutained = accountList.data.filter(item => !params.ids.includes(item.id))

        setAccountList({
          ...accountList,
          data: [
            ...sutained,
            ...updated
          ]
        })


      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }
  return {
    getAccountList,
    setComplete,
  }
}
