import React, {useState, useEffect, useCallback} from 'react'
import {Link, useParams, useNavigate} from 'react-router-dom'
import {defaultService} from '../../store/service'

import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil'
import {BrandAction, ProductAction} from '../../store/action'
import {alertAtom} from '../../store/recoil/alert'
import {authAtom} from '../../store/recoil/auth'
import {brandListAtom} from '../../store/recoil/brand'

import InputField from '../../module/input'
import Button from '../../module/button'
import Checkbox from '../../module/checkbox'
import Location from '../../module/location'
// import File from '../../module/file'
import Select from '../../module/select'
import TextArea from '../../module/textarea'
import Switch from '../../module/switch'
import File from '../../module/dropFile'

import {Style} from './style/Style'
import Image from '../../module/lazyImage'
import moment from 'moment/moment'
import Svg from '../../module/svg'

const Index = (props: any) => {

  const {id} = useParams()
  const navigate = useNavigate()

  const auth = useRecoilValue(authAtom)
  const setAlert = useSetRecoilState(alertAtom)

  const {getBrandList} = BrandAction()
  const brandList = useRecoilValue(brandListAtom)
  const [file, setFile] = useState<any>(null)
  const [state, setState] = useState<any>({
    title: '',
    price: 0,
    pay_type: '',
    file: file,
  })
  const [payType, setPayType] = useState<any>({
    dn_card: false,
    gx_card: false,
    gx_phone: false,
    gx_account: false
  })

  const handleChange = useCallback((e: any) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  }, [state])

  const handleSubmit = (e: any) => {
    if (e) e.preventDefault()
    const {title, brand_id, price} = state

    if (!brand_id) {
      setAlert({
        title: '확인',
        type: 'alert',
        message: '브랜드를 선택하세요',
        isShow: true
      })
      return
    }

    if (!payType.dn_card && !payType.gx_card && !payType.gx_phone && !payType.gx_account) {
      setAlert({
        title: '확인',
        type: 'alert',
        message: '결제 방법을 선택하세요',
        isShow: true
      });
      return;
    }

    setAlert({
      title: '확인',
      type: 'confirm',
      message: id ? '수정하시겠습니까?' : '등록하시겠습니까?' ,
      isShow: true,
      action: {
        method: () => {

          console.log(state)
          regisExcels({
            parent: state.brand_id,
            user_id: auth.id,
            pay_type: JSON.stringify([
              payType.dn_card,
              payType.gx_card,
              payType.gx_phone,
              payType.gx_account
            ]),
            file: state.file
          }).then((data) => {
            console.log("리턴")
            setState({})
          })
        }
      }
    })
  }

  useEffect(() => {
    getBrandList({
      page: 0,
      size: 500,
      title: ''
    })
  }, [])

  const regisExcels = async (params: any) => {

    // loader({
    //   isLoading: true
    // })

    const formData = new FormData()
    formData.append('parent', params.parent)
    formData.append('pay_type', params.pay_type)
    formData.append('user_id', params.user_id)
    formData.append('file', params.file)
    formData.append('options', JSON.stringify(params.options))

    return await defaultService.handleService({
      method: 'post',
      endPoint: '/pin/add/',
      params: formData
    }).then(
      response => {
        // loader({
        //   isLoading: false
        // })
        console.log('==>', response.data)
        // return response.data.payload.data

        setAlert({
          title: '확인',
          type: 'alert',
          message: '등록이 완료되었습니다.',
          isShow: true
        })
      },
      error => {
        // loader({
        //   isLoading: false
        // })
        setAlert({
          title: error.response.data.title,
          type: 'alert',
          message: error.response.data.message,
          isShow: true
        })
      }
    )
  }

  const downloadExcelTemplate = () => {
    const fileUrl = '/sample.xlsx'

    const link = document.createElement('a')
    link.href = fileUrl
    link.setAttribute('download', 'sample.xlsx')
    link.click()
  }

  return (
    <Style.Container>
      <Location location={{name: '핀번호 등록'}}/>
      <Style.Option>
        <Select
          id="brand"
          name="brand"
          title={'브랜드 선택'}
          size={'lg'}
          config={
            {
              title: 'title',
              value: 'id'
            }
          }
          isSelected={state.brand_id}
          value={brandList.data}
          onChange={(e)=> {
            setState({
              ...state,
              brand_id: e.id
            })
          }}
          required={false}
          readOnly={false}
          disabled={false}
        />
      </Style.Option>

      {!state.jsonData ?
        <File
          id={'excel'}
          name={'excel'}
          placeholder={'파일을 끌고오거나 클릭하세요.'}
          accept={['.xlsx', '.xls']}
          onChange={(props: any) => {
            setState({
              ...state,
              file: props.file,
              jsonData: props.jsonData
            })
          }}
        />
        :
        <Style.Table>
          <colgroup>
            <col/>
            <col width="100"/>
            <col width="100"/>
            <col width="100"/>
            <col width="100"/>
          </colgroup>
          <thead>
          <tr>
            <th>상품권 이름</th>
            <th>핀번호</th>
            <th>액면가</th>
            <th>판매가</th>
            <th>등록일</th>
          </tr>
          </thead>
          <tbody>
          {state.jsonData && state.jsonData.map((item: any, index: number) => {
            return (
              item[0] ? <tr key={index}>
                <td>
                  {item[0]}
                </td>
                <td>
                  {item[1]}
                </td>
                <td>
                  {item[2]}
                </td>
                <td>
                  {item[3]}
                </td>
                <td>{moment(item.created_at).format('YYYY.MM.DD')}</td>
              </tr> : ''
            )})}
          </tbody>
        </Style.Table>
      }

      <Style.Info>
        <Style.Description>
          * 이미 등록된 핀은 입력되지 않으며 중복된 핀은 하나만 입력됩니다. (확인 목록에는 표시됨)
        </Style.Description>
      <Style.JaegoButton>
        <Svg
          icon={'excel'}
          width={18}
          height={18}
          fill={'#45B058'}
          color={'#45B058'}
        />
        <Button
          size={'sm'}
          margin={'0 0 0 0'}
          padding={'.2rem 0 .2rem 1rem'}
          radius={'.5rem'}
          bgcolor={'transparent'}
          color={'#333333'}
          disabled={false}
          onClick={downloadExcelTemplate}
        >
          엑셀 양식 다운로드
        </Button>
      </Style.JaegoButton>
      </Style.Info>
      <Style.Title>결제설정</Style.Title>
      <Style.Category>
        <Checkbox
          id={'pay_type_0'}
          label={'다날 카드결제'}
          checked={payType.dn_card}
          onChange={(e) => setPayType({
            ...payType,
            dn_card: !payType.dn_card
          })}
        />
        <Checkbox
          id={'pay_type_1'}
          label={'갤럭시아 카드결제'}
          checked={payType.gx_card}
          onChange={(e) => setPayType({
            ...payType,
            gx_card: !payType.gx_card
          })}
        />
        <Checkbox
          id={'pay_type_2'}
          label={'갤럭시아 휴대폰'}
          checked={payType.gx_phone}
          onChange={(e) => setPayType({
            ...payType,
            gx_phone: !payType.gx_phone
          })}
        />
        <Checkbox
          id={'pay_type_3'}
          label={'갤럭시아 계좌이체'}
          checked={payType.gx_account}
          onChange={(e) => setPayType({
            ...payType,
            gx_account: !payType.gx_account
          })}
        />
      </Style.Category>

      <Style.Action>
        <Button
          size={'md'}
          margin={'1rem 1rem 1rem 0'}
          padding={'1rem 2rem'}
          radius={'.5rem'}
          bgcolor={'#3f3b3f'}
          color={'#ffffff'}
          fontSize={'1.4rem'}
          themecolor={'dark-gray'}
          disabled={!state.jsonData ? true : false}
          onClick={(e) => handleSubmit(e)}
        >
          {id ? '수정' : '등록'}
        </Button>

        <Button
          size={'md'}
          margin={'1rem 0'}
          padding={'1rem 2rem'}
          radius={'.5rem'}
          bgcolor={'#3f3b3f'}
          color={'#ffffff'}
          fontSize={'1.4rem'}
          themecolor={'white'}
          disabled={false}
          onClick={() => navigate('/pin')}
        >
          취소
        </Button>
      </Style.Action>
    </Style.Container>
  )
}

export default Index
