import {useNavigate} from 'react-router-dom'
import {defaultService} from '../service'
import {loaderAtom} from '../../store/recoil/loader'
import {useSetRecoilState, useRecoilValueLoadable, useRecoilState} from 'recoil'
import {alertAtom} from '../recoil/alert'
import {userListAtom, userAtom, userPointHistoryAtom, userSellerMoneyHistoryAtom} from '../recoil/user'
import {productAtom} from "../recoil/product";

export const UserAction = () => {

  const navigate = useNavigate()
  const loader = useSetRecoilState(loaderAtom)
  const setAlert = useSetRecoilState(alertAtom)
  const [userList, setUserList] = useRecoilState(userListAtom)
  const setUser = useSetRecoilState(userAtom)

  const [userPointHistory, setUserPointHistory] = useRecoilState(userPointHistoryAtom)
  const [userSellerMoneyHistory, setUserHistorySellerMoney] = useRecoilState(userSellerMoneyHistoryAtom)

  const getUserList = (params: any) => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'post',
      endPoint: '/user/list',
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })
        setUserList(response.data.payload)
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const getUser = (params: any) => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'get',
      endPoint: `/user/${params.id}`
    }).then(
      response => {
        loader({
          isLoading: false
        })
        setUser(response.data.payload[0])
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const stopUser = (params: any) => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'post',
      endPoint: params.status === 0 || params.status === 1 ? '/user/setState' : '/user/deleteUser',
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })

        // const filteredList = userList.data.filter(item => !params.ids.includes(item.id))
        // const filteredList = [...userList.data].find(item => item.id === params.userId)
        //
        //
        // setUserList({
        //   ...userList,
        //   data: filteredList
        // })
        window.location.reload()
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const getSellerMoneyHistory = (
    userId: string | number,
    current_page: string | number,
    size: string | number
  ) => {
    loader({isLoading: true})

    defaultService.handleService({
      endPoint: `/sellerMoney?userId=${userId}&current_page=${current_page}&size=${size}`,
      method: 'get'
    })
      .then(
        response => {
          setUserHistorySellerMoney(response.data.payload)
        },
        error => {
          setAlert({
            title: error.name,
            type: 'alert',
            message: error.message,
            isShow: true
          })
        }
      )
      .finally(() => {
        loader({isLoading: false})
      })
  }

  const sellerMoneyOffer = (params:any, setPoint: any) => {
    defaultService.handleService({
      method: 'post',
      endPoint: '/sellerMoney/moneyOffer',
      params: params
    }).then(
      (response: any) => {
        const temp: any = [...userSellerMoneyHistory.result]
        temp.unshift(response.data.payload)
        setUser(prev => {
          return {
            ...prev,
            seller_money: Number(prev.seller_money) + ( Number(params.type) * Number(params.point) )
          }
        })
        setUserHistorySellerMoney(prev => {
          return {
            count: prev.count + 1,
            result : temp
          }
        })
        setPoint('0')
        setAlert({
          title: `셀러머니 ${params.type === 1 ? '충전' : '차감'}`,
          type: 'alert',
          message: `${params.type === 1 ? '충전' : '차감'} 성공`,
          isShow: true
        })      },
      error => {
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const confirmSellerMoney = (params: any) => {
    loader( {isLoading: true})

    defaultService.handleService({
      method: 'put',
      endPoint: '/sellerMoney',
      params: params
    }).then(
      (response: any) => {

      },
      (error: any) => {
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    ).finally(() => {
      window.location.reload()
      loader({isLoading: false})
    })
  }

  const getPointHistory = (
    userId: string | number,
    current_page: string | number,
    size: string | number
  ) => {
    loader({isLoading: true})

    defaultService.handleService({
      endPoint: `/sellerMoney/getHistory?userId=${userId}&current_page=${current_page}&size=${size}`,
      method: 'get'
    })
      .then(
        response => {
          setUserPointHistory(response.data.payload)
        },
        error => {
          setAlert({
            title: error.name,
            type: 'alert',
            message: error.message,
            isShow: true
          })
        }
      )
      .finally(() => {
        loader({isLoading: false})
      })
  }

  const pointOffer = (params: any, setPoint: any) => {
    loader({isLoading: true})

    defaultService.handleService({
      method: 'post',
      endPoint: '/sellerMoney/pointOffer',
      params: params
    })
      .then(
        (response: any) => {
          const temp: any = [...userPointHistory.result]
          temp.unshift(response.data.payload)
          setUser(prev => {
            return {
              ...prev,
              cash: Number(prev.cash) + ( Number(params.type) * Number(params.point) )
            }
          })
          setUserPointHistory(prev => {
            return {
              count: prev.count + 1,
              result : temp
            }
          })
          setPoint('0')
          setAlert({
            title: `포인트 ${params.type === 1 ? '충전' : '차감'}`,
            type: 'alert',
            message: `${params.type === 1 ? '충전' : '차감'} 성공`,
            isShow: true
          })
        },
        error => {
          setAlert({
            title: error.name,
            type: 'alert',
            message: error.message,
            isShow: true
          })
        }
      )
      .finally(() => {
        loader({isLoading: false})
      })
  }

  return {
    getUserList,
    getUser,
    stopUser,
    sellerMoneyOffer,
    getSellerMoneyHistory,
    getPointHistory,
    confirmSellerMoney,
    pointOffer,
  }
}
