import React, {useRef, useEffect, lazy, Suspense} from 'react'
import {Route, Routes} from 'react-router-dom'
import {SocialAction} from '../../store/action'

const Kakao = () => {

  const hash = new URL(window.location.href).searchParams.get('code')
  const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_REST_API_KEY}&redirect_uri=${process.env.REACT_APP_KAKAO_REDIRECT_URI}&response_type=code`
  const {getKakaoToken} = SocialAction()

  const initKakaoLogin = () => {
    if (!hash) {
      window.location.href = KAKAO_AUTH_URL
    } else {
      getKakaoToken(hash)
    }
  }

  useEffect(() => {
    initKakaoLogin()
  }, [])

  return (
    <Suspense fallback={''}>
      <Routes>
        <Route
          path="/"
          element={''}
        />
        {/*
          // 결과화면 출력할때 사용..
          <Route
            path="/kakao"
            element={<>kakao</>}
          />
         */}
      </Routes>
    </Suspense>
  )
}

export default Kakao
