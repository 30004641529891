import React, {useState, useEffect, useCallback} from 'react'

import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil'
import {SettingAction} from '../../store/action'
import {alertAtom} from '../../store/recoil/alert'
import {bizAtom} from '../../store/recoil/setting'

import InputField from '../../module/input'
import Button from '../../module/button'
import Location from '../../module/location'

import {Style} from './style/Style'

const Index = () => {

  const setAlert = useSetRecoilState(alertAtom)
  const {getBiz, updateBiz} = SettingAction()
  const [biz, setBiz] = useRecoilState(bizAtom)
  const [state, setState] = useState<any>({
    bizName: '',
    bizAddress: '',
    generalLine: '',
    representName: '',
    bizRegisNumber: '',
    privacyManager: '',
    telecomSalesNumber: '',
    submitted: false
  })

  const handleChange = useCallback((e: any) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  }, [state])

  const handleSubmit = (e: any) => {
    if (e) {
      e.preventDefault()
    }

    const {
      bizName,
      bizAddress,
      generalLine,
      representName,
      bizRegisNumber,
      privacyManager,
      telecomSalesNumber
    } = state

    if (!bizName ||
      !bizAddress ||
      !generalLine ||
      !representName ||
      !bizRegisNumber ||
      !privacyManager ||
      !telecomSalesNumber
    ) {
      setAlert({
        title: '확인',
        type: 'alert',
        message: `내용을 입력하세요`,
        isShow: true
      })
      return
    }

    setState({
      ...state,
      submitted: true
    })

    updateBiz({
      bizName: state.bizName,
      bizAddress: state.bizAddress,
      generalLine: state.generalLine,
      representName: state.representName,
      bizRegisNumber: state.bizRegisNumber,
      privacyManager: state.privacyManager,
      telecomSalesNumber: state.telecomSalesNumber
    })
  }

  useEffect(() => {
    getBiz({
      type: 'biz'
    })
  }, [])

  useEffect(() => {
    setState({
      ...state,
      bizName: biz.bizName,
      bizAddress: biz.bizAddress,
      generalLine: biz.generalLine,
      representName: biz.representName,
      bizRegisNumber: biz.bizRegisNumber,
      privacyManager: biz.privacyManager,
      telecomSalesNumber: biz.telecomSalesNumber
    })
  }, [biz])

  return (
    <Style.Container>
      <Location location={{name: '사업자 정보'}}/>
      <Style.Content>
        <Style.Section>
          <InputField
            useAnimation={true}
            type={'text'}
            id="bizName"
            name="bizName"
            placeholder="사업자명"
            value={state.bizName}
            onChange={(e) => handleChange(e)}
            check={false}
          />

          <InputField
            useAnimation={true}
            type={'text'}
            id="bizAddress"
            name="bizAddress"
            placeholder="사업자 주소"
            value={state.bizAddress}
            onChange={(e) => handleChange(e)}
            check={false}
          />
        </Style.Section>

        <Style.Section>
          <InputField
            useAnimation={true}
            type={'text'}
            id="representName"
            name="representName"
            placeholder="대표자명"
            value={state.representName}
            onChange={(e) => handleChange(e)}
            check={false}
          />

          <InputField
            useAnimation={true}
            type={'text'}
            id="bizRegisNumber"
            name="bizRegisNumber"
            placeholder="사업자 번호"
            value={state.bizRegisNumber}
            onChange={(e) => handleChange(e)}
            check={false}
          />
        </Style.Section>

        <Style.Section>
          <InputField
            useAnimation={true}
            type={'text'}
            id="telecomSalesNumber"
            name="telecomSalesNumber"
            placeholder="통신판매 번호"
            value={state.telecomSalesNumber}
            onChange={(e) => handleChange(e)}
            check={false}
          />

          <InputField
            useAnimation={true}
            type={'text'}
            id="generalLine"
            name="generalLine"
            placeholder="대표번호"
            value={state.generalLine}
            onChange={(e) => handleChange(e)}
            check={false}
          />
        </Style.Section>

        <Style.Section>
          <InputField
            useAnimation={true}
            type={'text'}
            id="privacyManager"
            name="privacyManager"
            placeholder="개인정보관리 책임자"
            value={state.privacyManager}
            onChange={(e) => handleChange(e)}
            check={false}
          />
        </Style.Section>

        <Style.Action>
          <Button
            size={'md'}
            margin={'1rem 1rem 1rem 0'}
            padding={'1rem 2rem'}
            radius={'.5rem'}
            bgcolor={'#3f3b3f'}
            color={'#ffffff'}
            fontSize={'1.4rem'}
            themecolor={'dark-gray'}
            disabled={false}
            onClick={(e) =>
              setAlert({
                title: '확인',
                type: 'confirm',
                message: '수정하시겠습니까?',
                isShow: true,
                action: {
                  method: () => {
                    handleSubmit(e)
                  }
                }
              })}
          >
            {state.bizName ? '수정' : '등록'}
          </Button>
        </Style.Action>
      </Style.Content>
    </Style.Container>
  )
}

export default Index
