import {useNavigate} from 'react-router-dom'
import {defaultService} from '../service'
import {loaderAtom} from '../../store/recoil/loader'
import {useSetRecoilState, useRecoilValueLoadable, useRecoilState} from 'recoil'
import {alertAtom} from '../recoil/alert'
import {faqListAtom, faqAtom} from '../recoil/faq'

export const FaqAction = () => {

  const navigate = useNavigate()
  const loader = useSetRecoilState(loaderAtom)
  const setAlert = useSetRecoilState(alertAtom)
  const [faqList, setFaqList] = useRecoilState(faqListAtom)
  const setFaq = useSetRecoilState(faqAtom)

  const getFaqList = (params: any) => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'post',
      endPoint: '/faq/list',
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })
        setFaqList(response.data.payload)
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const getFaq = (params: any) => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'get',
      endPoint: `/faq/detail/${params.id}`
    }).then(
      response => {
        loader({
          isLoading: false
        })
        setFaq(response.data.payload)
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const regisFaq = (params: any) => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'post',
      endPoint: params.id ? '/faq/update' : '/faq/add',
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })
        navigate('/faq')
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const deleteFaq = (params: any) => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'post',
      endPoint: '/faq/delete',
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })

        const filteredList = faqList.data.filter(item => !params.ids.includes(item.id))
        setFaqList({
          ...faqList,
          data: filteredList
        })
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  return {
    getFaqList,
    getFaq,
    regisFaq,
    deleteFaq
  }
}
