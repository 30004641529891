import React, {useState} from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  position: relative;
`

const Label = styled.label<{ $isActive?: boolean }>`
  display: inline-block;
  text-align: left;
  font-size: 1.4rem;
  font-weight: 400;
  color: #B4B4B4FF;
  position: absolute;
  width: 100%;
  top: 2rem;
  transform: translate(2rem, 0) scale(1);
  transform-origin: top left;
  transition: all 0.2s ease-out;
  transform: ${({$isActive}) => ($isActive ? 'translate(2rem, -20%) scale(.90)' : '')};
`

const TextArea = styled.textarea<{ $useAnimation?: boolean, $isActive?: boolean, height?: string }>`
  display: flex;
  flex: 1;
  border: .1rem solid #efebef;
  border-radius: 1rem;
  padding: 1rem;
  background: #f8f8f8;
  font-size: 1.4rem;
  height: ${({height}) => (height ? `${height}rem` : '5rem')};
  padding: ${({
                $useAnimation,
                $isActive
              }) => ($useAnimation ? ($isActive ? '3.8rem 1rem .6rem 2rem' : '2rem 1rem 1rem 2rem') : '1rem')};
`

const Length = styled.span`
  font-size: 1.2rem;
  margin-left: .2rem;
`

const Required = styled.span`
  font-size: 1.4rem;
  margin-right: .2rem;
`

const Button = styled.button<{
  $bgcolor: string,
  $color: string,
  width: string,
  $borderRadius: string
}>`
  font-size: 1.4rem;
  margin: 1rem;
  color: ${({$color}) => ($color ? $color : '#666666')};
  background: ${({$bgcolor}) => ($bgcolor ? $bgcolor : 'red')};
  border-radius: ${({$borderRadius}) => ($borderRadius ? $borderRadius : '.5rem')};
  width: ${({width}) => (width ? width : '5rem')};
  height: calc(100%);
`

interface TextAreaProps {
  id?: string
  name?: string
  useAnimation?: boolean
  placeholder?: string
  value?: string
  height?: string
  required?: boolean
  minLength?: number
  maxLength?: number
  button?: any
  onButtonClick?: any
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
}

const Index = ({
                 id,
                 name,
                 useAnimation,
                 required,
                 placeholder,
                 value,
                 height,
                 minLength,
                 maxLength,
                 onChange,
                 button,
                 onButtonClick
               }: TextAreaProps) => {
  const [text, setText] = useState(value)
  const [isActive, setIsActive] = useState<boolean>(false)

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value)
    setIsActive(e.target.value !== '' ? true : false)
    onChange?.(e)
  }

  const onClick = (e: any) => {
    setText('')
    onButtonClick?.(e)
  }

  return (
    <Container>
      {
        useAnimation &&
          <Label
              htmlFor={id}
              $isActive={isActive}
          >
            {required && <Required>* </Required>}
            {placeholder}

            <Length>
              {minLength && !maxLength && ` (최소 ${minLength}자)`}
              {maxLength && !minLength && ` (최대 ${maxLength}자)`}
              {minLength && maxLength && ` (${minLength} ~ ${maxLength}자)`}
            </Length>
          </Label>
      }
      <TextArea
        id={id}
        name={name}
        placeholder={(!useAnimation ? placeholder : '')}
        $useAnimation={useAnimation}
        $isActive={isActive}
        onChange={handleChange}
        height={height}
        value={value || value === '' ? value : text}
      >
      </TextArea>

      {
        button?.isBtn &&
          <Button
              $bgcolor={button.bgcolor}
              $color={button.color}
              width={button.width}
              $borderRadius={button.borderRadius}
              onClick={(e) => onClick(e)}
          >
            {button.text}
          </Button>
      }
    </Container>
  )
}

export default Index
