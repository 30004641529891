import {atom} from 'recoil'

interface BrandList {
  pageInfo: {
    page: number
    size: number
    total: number
  },
  data: {
    id: number;
    image: string;
    title: string;
    order: number;
    ppL: number;
    createdAt: string;
    updatedAt: string;
    userid: number;
    categoryId: number;
    is_gift: 0 | 1;
    pay_type: [boolean, boolean, boolean, boolean];
    high_percent: number;
    low_percent: number;
    sale_percent: number;
    sale_count: null;
  }[]
}

interface Category {
  id?: number;
  image?: string;
  title?: string;
  order?: number;
  createdAt?: string;
  updatedAt?: string;
  userid?: number;
}

interface Brand {
  title: string;
  order: number;
  ppL: number;
  userId: number;
  categoryId: number;
  pay_type?:any,
  is_gift: 0 | 1;
  high_percent?: number;
  low_percent?: number;
  sale_percent?: number;
  sale_count?: number;
  image: any;
}

export const brandListAtom = atom<BrandList>({
  key: 'brandListAtom',
  default: {
    data: [],
    pageInfo: {
      page: 0,
      size: 0,
      total: 0
    }
  }
})

export const categoryAtom = atom<Category[]>({
  key: 'categoryAtom',
  default: []
})

export const brandAtom = atom<Brand>({
  key: 'brandAtom',
  default: {
    title: '',
    order: 0,
    ppL: 0,
    userId: 0,
    categoryId: 0,
    is_gift: 0,
    image: null
  }
})
