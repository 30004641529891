import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-self: stretch;
  min-height: 9.7rem;
`

const Title = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  margin: 1.5rem 0;
`

const Section = styled.div`
  display: flex;
  flex: 1;
  gap: 1rem;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 50%;
  align-self: stretch;
  flex-grow: 1;

  @media (max-width: 1008px) {
    max-width: 100%;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
`

const Util = styled.div`
  display: flex;
  align-self: flex-start;
  margin: 1rem 0;
  gap: 1rem;
`

const Option = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  min-width: 20rem;
  margin: 1rem 0;
  gap: 1rem;
`

const Category = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  min-width: 20rem;
  margin: 0 0;
  gap: 1rem;
`

const Action = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`

const Thumbnail = styled.img`
  width: 10rem;
  height: 10rem;
`

const GiftConUtil = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const GiftConButtonWrap = styled.div`
  display: flex;
  gap: 1rem;
`

const Line = styled.div`
  margin: 3rem 0 1rem;
  border-top: #ddd solid 1px;
  height: 1rem;
  width: 100%;
`

const GiftConRegisWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items:flex-end;
  gap: 2rem;
  > div:first-child {
    display: block;
    flex: 1;
  }
  > div:last-child {
    flex: 4;
  }
`

const Table = styled.table`
  border-radius: 1rem;
  border-collapse: collapse;
  overflow: hidden;
  margin: 0 0 0 0;

  thead {
    font-size: 1.3rem;
    text-align: left;
  }

  thead th {
    font-weight: 400;
    color: #81858c;
    background: #f3f5f7;
    padding: 1.4rem 1.2rem;
  }

  th, td {
    padding: 1rem;
  }

  td {
    font-size: 1.4rem;
    font-weight: 350;
    color: #777777;
    border: 0;
    border-bottom: .1rem solid #efebef;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 1.2rem;
    cursor: pointer;
  }

  td.email {
    min-width: 20rem;
    max-width: 20rem;
  }

  td.name {
    min-width: 12rem;
    max-width: 12rem;
  }

  td.nodata {
    border-bottom: 0;
  }
`

const Description = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  min-width: 20rem;
  margin: .4rem 0;
  font-size: 1.3rem;
  font-weight: 400;
  color: crimson;
`

const JaegoButton = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
  font-size: 1.5rem;
  font-weight: 600;
  border: .1rem solid #efebef;
  border-radius: 1rem;
  margin-top: 1rem;
  flex-grow: 0;
  flex-shrink: 0;
`

const Info = styled.div`
  display: flex;
  margin: .5rem 0;
  align-items: center;
  justify-content: space-between;
`

export const Style = {
  Line,
  Container,
  Title,
  Table,
  Section,
  Content,
  Action,
  Util,
  Option,
  Category,
  Thumbnail,
  GiftConUtil,
  GiftConButtonWrap,
  GiftConRegisWrap,
  Description,
  JaegoButton,
  Info
}
