import React, {useState, useEffect, useCallback} from 'react'
import {useRecoilValue, useSetRecoilState} from 'recoil'
import {Link, useLocation, useParams, useNavigate} from 'react-router-dom'
import styled from 'styled-components'
import moment from 'moment'

import {PinAction} from '../../store/action'
import {pinListAtom} from '../../store/recoil/pin'
import {alertAtom} from '../../store/recoil/alert'

import Pagination from '../../module/pagination/'
import Checkbox from '../../module/checkbox'
import Button from '../../module/button'
import Location from '../../module/location'
import InputField from '../../module/input'
import Select from '../../module/select'
import Image from "../../module/lazyImage"
import Svg from '../../module/svg'

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-self: stretch;
`

const Table = styled.table`
  border-radius: 1rem;
  border-collapse: collapse;
  overflow: hidden;
  margin-top: 2rem;

  thead {
    font-size: 1.3rem;
    text-align: left;
  }

  thead th {
    font-weight: 400;
    color: #81858c;
    background: #f3f5f7;
    padding: 1.4rem 1.2rem;
  }

  th, td {
    padding: 1rem;
  }

  td {
    font-size: 1.4rem;
    font-weight: 350;
    color: #777777;
    border: 0;
    border-bottom: .1rem solid #efebef;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 1.2rem;
    cursor: pointer;
  }

  td.email {
    min-width: 20rem;
    max-width: 20rem;
  }

  td.name {
    min-width: 12rem;
    max-width: 12rem;
  }

  td.nodata {
    border-bottom: 0;
  }
`

const Util = styled.div`
  display: flex;
  padding: 0 0;
  margin-top: 1rem;
  align-self: flex-start;
`

const Search = styled.form`
  display: flex;
  flex: 1;
  padding: 0 0;
  margin-top: 1rem;
  align-self: flex-start;
  width: 49rem;
  gap: 1rem;
`

const SearchType = styled.div`
  display: flex;
  width: 12rem;
  max-width: 12rem;
  gap: 1rem;
`

const Type = styled.div`
  display: flex;
  padding: 0 0;
  align-self: flex-start;
  margin: 1rem 0 0 .4rem;
  width: 40rem;
  gap: 1rem;
`

const Nodata = styled.div`
  display: flex;
  font-size: 1.4rem;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
`

const Actions = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 0;
`

const Img = styled.img`
  max-width: 5rem;
  max-height: 5rem;
`

const JaegoButton = styled.div`
  display: flex;
  align-items: center;
  padding: .7rem 2rem;
  font-size: 1.5rem;
  font-weight: 600;
  border: .1rem solid #efebef;
  border-radius: .5rem;
  margin: 1rem 1rem 0 0;
`

const Num = styled.div`
  font-size: 1.4rem;
`

const JaegoContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  margin-bottom: 2rem;
`

const Options = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: stretch;
  margin-bottom: 1rem;
  
  img {
    width: 100%;
    height: auto;
  }
  
  table {
    width: 100%;
    height: fit-content;
  }
`

const ImageWrap = styled.div`
  display: flex;
  gap: 1rem;
  margin: 0 2rem 0 0;
  width: 12rem;
  height: 12rem;
  align-items: center;
  border: 0.1rem solid #efebef;
  overflow: hidden;
  border-radius: 1rem;
  position: relative;
  background: #ffffff;
`

const TableNoraml = styled.table`
  border-radius: 1rem;
  border-collapse: collapse;
  overflow: hidden;
  margin-top: 0;
  
  thead {
    font-size: 1.3rem;
    text-align: left;
  }

  thead th {
    font-weight: 400;
    color: #81858c;
    background: #f3f5f7;
    padding: 1.4rem 1.2rem;

    &.text-right {
      text-align: right
    }
  }

  th, td {
    padding: 1rem;
    white-space: nowrap;
  }

  td {
    font-size: 1.4rem;
    font-weight: 350;
    color: #777777;
    border: 0;
    border-bottom: .1rem solid #efebef;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 1.2rem;
    cursor: pointer;
    
    &.text-right {
      text-align: right
    }

    &.text-center {
      text-align: center
    }
  }
`

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0;
  font-size: 1.5rem;
  font-weight: 600;
  
`

const List = () => {
  const {id} = useParams()
  const navigate = useNavigate()
  const [isCheckAll, setIsCheckAll] = useState<boolean>(false)
  const [isCheck, setIsCheck] = useState<any>([])
  const [state, setState] = useState<any>({
    keywordType: 'product',
    keyword: '',
    promotion: '',
    sort: '',
    by: '',
    page: 0,
    size: 0
  })
  const {getPinList, deletePin, getPinInfo} = PinAction()
  const setAlert = useSetRecoilState(alertAtom)
  const pinList = useRecoilValue(pinListAtom)

  const [currentPage, setCurrentPage] = useState<number>(0)
  const pageSize: number = 10

  const [info, setInfo] = useState<any>({})

  const handleChange = useCallback((e: any) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  }, [state])

  const handleSelectAll = (e: any) => {
    let isCheckArray: any = []

    pinList.data.map((item, index) => (
      isCheckArray[index] = item.id
    ))

    setIsCheckAll(!isCheckAll)
    setIsCheck(isCheckArray)

    if (isCheckAll) {
      setIsCheck([])
    }
  }

  const handleSelect = (e: any) => {
    const {id, checked} = e.target

    setIsCheck((prevState: any) => {
      if (!checked) {
        return prevState.filter((item: any) => item !== Number(id))
      } else {
        return [...prevState, Number(id)]
      }
    })
  }

  const deleteSelected = () => {
    if (isCheck.length < 1) {
      setAlert({
        title: '오류',
        type: 'alert',
        message: '선택된 항목이없습니다',
        isShow: true
      })
      return
    }

    setAlert({
      title: '확인',
      type: 'confirm',
      message: `선택된 핀을 삭제 처리하시겠습니까?`,
      isShow: true,
      action: {
        method: () => {
          deletePin({
            ids: isCheck
          })
        }
      }
    })
  }

  useEffect(() => {
    getPinInfo({
      id: id
    }).then((res) => {
      setInfo(res)
    })
  }, [])

  useEffect(() => {
    // const page = pageNum ? Number(pageNum) - 1 : 0

    getPinList({
      id: id,
      keywordType: state.keywordType,
      keyword: state.keyword,
      sort: state.sort,
      by: state.by,
      page: 0,
      size: pageSize
    })
    // setCurrentPage(page)

  }, [])

  useEffect(() => {
    // const page = pageNum ? Number(pageNum) - 1 : 0
    getPinList({
      id: id,
      keywordType: state.keywordType,
      keyword: state.keyword,
      sort: state.sort,
      by: state.by,
      page: 0,
      size: pageSize
    })
  }, [state.promotion, state.sort, state.by])

  const handleSubmit = (e: any) => {
    if (e) {
      e.preventDefault()
    }
    getPinList({
      id: id,
      keywordType: state.keywordType,
      keyword: state.keyword,
      sort: state.sort,
      by: state.by,
      page: 0,
      size: pageSize
    })
    navigate(`/pin/1`)
  }

  return (
    <Container>
      <Location location={{name: '핀번호 관리 > 재고관리'}}/>

      <JaegoContent>
        <Options>
          <ImageWrap>
            <img src={process.env.REACT_APP_IMAGE_URL + info?.image}/>
          </ImageWrap>
          <TableNoraml>
            <colgroup>
              <col/>
              <col width="100"/>
              <col width="100"/>
              <col width="100"/>
            </colgroup>
            <thead>
            <tr>
              <th>상품권 이름</th>
              <th>액면가</th>
              <th>판매가</th>
              <th>등록일</th>
            </tr>
            </thead>
            <tbody>
              <tr>
                <td>{info?.name}</td>
                <td>{info?.original_price?.toLocaleString()}</td>
                <td>{info?.sale_price?.toLocaleString()}</td>
                <td>{moment(info?.created_at).format('YYYY.MM.DD')}</td>
              </tr>
            </tbody>
          </TableNoraml>
        </Options>
      </JaegoContent>

      <Title>
        핀 재고 목록(미발행, {pinList.pageInfo.total}건)
      </Title>

      <Table>
        <colgroup>
          <col width="30"/>
          <col/>
          <col width="100"/>
          <col width="100"/>
          <col width="100"/>
          <col width="100"/>
        </colgroup>
        <thead>
          <tr>
            <th>
              <Checkbox
                id={'all'}
                checked={isCheckAll}
                onChange={(e) => handleSelectAll(e)}
              />
            </th>
            <th>핀번호</th>
            <th>상태</th>
            <th>액면가</th>
            <th>판매가</th>
            <th>등록일</th>
          </tr>
        </thead>

        {pinList.data.length > 0 ?
          <tbody>
          {pinList.data.map((item: any, index: number) => (
            <tr key={index}>
              <td>
                <Checkbox
                  id={`${item.id}`}
                  checked={isCheck.includes(item.id)}
                  onChange={(e) => handleSelect(e)}
                />
              </td>
              <td>
                {item.pin}
              </td>
              <td>
                {item.status === 2 ? '삭제' : item.status === 0 ? '미사용' : '사용'}
              </td>
              <td>
                {item.original_price.toLocaleString()}
              </td>
              <td>
                {item.sale_price.toLocaleString()}
              </td>
              <td><Num>{moment(item.created_at).format('YYYY.MM.DD')}</Num></td>
            </tr>
          ))}
          </tbody>
          : <tbody><tr>
            <td className={'nodata'} colSpan={6}><Nodata>검색된 데이터가 없습니다</Nodata></td>
          </tr></tbody>}
      </Table>

      {/*<Actions>*/}
      {/*  <Button*/}
      {/*    size={'md'}*/}
      {/*    margin={'1rem 0 0 0'}*/}
      {/*    radius={'.5rem'}*/}
      {/*    bgcolor={'#3f3b3f'}*/}
      {/*    color={'#ffffff'}*/}
      {/*    themecolor={'red'}*/}
      {/*    disabled={false}*/}
      {/*    onClick={() => deleteSelected()}*/}
      {/*  >*/}
      {/*    선택 삭제*/}
      {/*  </Button>*/}
      {/*</Actions>*/}

      <Pagination
        totalRecord={pinList.pageInfo.total}
        pageSize={pageSize}
        currentPage={currentPage}
        onChange={(page) => navigate(`/pin/${(page + 1)}`)}
      />
    </Container>
  )
}
export default List
