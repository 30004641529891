import React, {useState} from 'react'
import {Link, NavLink, useLocation} from 'react-router-dom'
import styled, {css} from 'styled-components'
import Svg from '../../module/svg/'
import {authAtom} from '../../store/recoil/auth'
import {useRecoilValue} from 'recoil';

import {Style} from './style/Nav'

const category = [
  {
    id: 0,
    label: '상품 관리',
    sub: [
      {
        id: 's0',
        label: '상품 목록',
        path: '/product'
      },
      {
        id: 's0',
        label: '등록',
        path: '/product/regis'
      }
    ]
  },
  {
    id: 1,
    label: '브랜드 관리',
    sub: [
      {
        id: 's0',
        label: '브랜드 목록',
        path: '/brand'
      },
      {
        id: 's0',
        label: '등록',
        path: '/brand/regis'
      },
    ]
  },
  {
    id: 2,
    label: '상품권 관리',
    sub: [
      {
        id: 's0',
        label: '상품권 목록',
        path: '/coupon'
      },
      {
        id: 's0',
        label: '발행',
        path: '/coupon/regis'
      }
    ]
  },
  {
    id: 3,
    label: '핀번호 관리',
    sub: [
      {
        id: 's0',
        label: '핀번호 목록',
        path: '/pin'
      },
      {
        id: 's0',
        label: '발행',
        path: '/pin/regis'
      }
    ]
  },
  {
    id: 4,
    label: '매출 관리',
    path: '/sales'
  },
  {
    id: 5,
    label: '매입 관리',
    path: '/purchase'
  },
  {
    id: 6,
    label: '정산 관리',
    path: '/account'
  },
  {
    id: 7,
    label: '회원 관리',
    path: '/user'
  },
  {
    id: 8,
    label: '공지사항',
    path: '/notice'
  },
  {
    id: 9,
    label: '푸시 메시지',
    path: '/push'
  },
  {
    id: 10,
    label: '이벤트',
    sub: [
      {
        id: 's0',
        label: '이벤트 목록',
        path: '/event'
      },
      {
        id: 's0',
        label: '등록',
        path: '/event/regis'
      }
    ]
  },
  {
    id: 11,
    label: '문의 / FAQ',
    sub: [
      {
        id: 's0',
        label: '문의 목록',
        path: '/qna'
      },
      {
        id: 's0',
        label: 'FAQ',
        path: '/faq'
      }
    ]
  },
  {
    id: 12,
    label: '설정',
    sub: [
      {
        id: 's0',
        label: '환경설정',
        path: '/setting'
      },
      {
        id: 's0',
        label: '사업자등록정보',
        path: '/business'
      },
      {
        id: 's0',
        label: '약관 및 정책',
        path: '/policy'
      }
    ]
  }
]

const Index = () => {

  const auth = useRecoilValue(authAtom)
  const [isOpen, setIsOpen] = useState<any>([0, 1, 2, 3])

  const getStatus = (id: number) => {
    return isOpen.findIndex((element: any) => element === id) > -1 ? true : false
  }

  const handleChange = (id: number) => {
    setIsOpen(() => {
      return (
        getStatus(id) ? isOpen.filter((item: any) => item !== id) : isOpen.concat(id)
      )
    })
  }

  return (
    <Style.Container>
      <Style.Nav>
        {category.map((item: any, index: number) => {
            return (
              <Style.NavItem key={index}>
                {
                  !item.path ?
                    <Style.NavItemLabel
                      selected={getStatus(item.id)}
                      onClick={() => handleChange(item.id)}
                    >
                      {item.label}
                      {
                        item.sub &&
                          <Svg
                              icon={'arrow'}
                              width={10}
                              height={10}
                              color={'#666666'}
                          />
                      }
                    </Style.NavItemLabel>
                    :
                    <Style.NavLinkItemLabel to={item.path}>
                      {item.label}
                    </Style.NavLinkItemLabel>
                }

                {item.sub &&
                  <Style.NavSubItem selected={getStatus(item.id)}>
                    {item.sub.map((sub: any, subIndex: number) => {
                        return (
                          <Style.NavSubItemLabel
                            key={subIndex}
                            to={sub.path}
                            end
                          >
                            {sub.label}
                          </Style.NavSubItemLabel>
                        )
                      })}
                  </Style.NavSubItem>}
              </Style.NavItem>
            )
          })}
      </Style.Nav>
    </Style.Container>
  )
};

export default Index
