import {useNavigate} from 'react-router-dom'
import {defaultService} from '../service'
import {loaderAtom} from '../../store/recoil/loader'
import {useSetRecoilState, useRecoilValueLoadable, useRecoilState} from 'recoil'
import {alertAtom} from '../recoil/alert'
import {productListAtom, productAtom} from '../recoil/product'
import {Simulate} from "react-dom/test-utils";
import load = Simulate.load;

export const ProductAction = () => {

  const navigate = useNavigate()
  const loader = useSetRecoilState(loaderAtom)
  const setAlert = useSetRecoilState(alertAtom)
  const [productList, setProductList] = useRecoilState(productListAtom)
  const setProduct = useSetRecoilState(productAtom)

  const getProductList = (params: any) => {
    loader({isLoading: true})
    defaultService.handleService({
      method: 'post',
      endPoint: '/product/admin/list',
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })
        setProductList(response.data.payload)
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const getGiftConList = (id: string, size: string, current_page: number, setGiftCon: any) => {
    loader({isLoading: true})
    defaultService.handleService({
      method: 'get',
      endPoint: `/list/product/giftcon?product_id=${id}&size=${size}&current_page=${current_page-1}`,
    }).then(
      response => setGiftCon(response.data.payload),
      error => console.log(error)
    ).finally(() => {
      loader({isLoading: false})
    })
  }

  // 기프티콘 등록
  const readBarcode = async (file: any) => {
    return await defaultService.handleService({
      method: 'post',
      endPoint: '/product/vision',
      params: file
    }).then(
      response => response.data,
      error => console.log(error)
    )
  }

  const regiGiftCon = async (param: any) => {
    loader({isLoading: true})
    return await defaultService.handleService({
      endPoint: '/product/admin/add/giftcon',
      method: 'post',
      params: param
    }).then(
      response => response.data,
      error => {
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    ).finally(() => {
      loader({isLoading: false})
    })
  }

  const getProduct = (params: any) => {

    loader({isLoading: true})
    defaultService.handleService({
      method: 'post',
      endPoint: `/product/admin/detail`,
      params: params
    }).then(
      response => {
        // loader({
        //   isLoading: false
        // })
        setProduct(response.data.payload.product)
      },
      error => {
        // loader({
        //   isLoading: false
        // })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const regisProduct = (params: any) => {

    loader({
      isLoading: true
    })

    const formData = new FormData()
    formData.append('sell_type', params.sellType)
    formData.append('brand_id', params.brand_id)
    formData.append('title', params.title)
    formData.append('price', params.price)
    formData.append('hot', params.hot)
    formData.append('seller', params.seller_pick)
    formData.append('giftsale', params.giftsale)
    formData.append('hot_order', params.hot_order)
    formData.append('pick_order', params.seller_pick_order)
    formData.append('giftsale_order', params.giftsale_order)
    formData.append('is_gift', params.is_gift)
    formData.append('pay_type', params.pay_type)
    formData.append('is_main', params.is_main)
    formData.append('is_use', params.is_use)
    formData.append('file', params.file)
    formData.append('resell_promotion', '')
    formData.append('isbarcode', '0')
    formData.append('memo', params.memo)

    defaultService.handleService({
      method: 'post',
      endPoint: '/product/admin/add',
      params: formData
      }).then(
      response => {
        loader({
          isLoading: false
        })
        navigate('/product')
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const deleteProduct = (params: any) => {
    loader({isLoading: true})
    defaultService.handleService({
      method: 'post',
      endPoint: '/product/admin/delete',
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })

        const filteredList = productList.data.filter(item => !params.ids.includes(item.product_id))
        setProductList({
          ...productList,
          data: filteredList
        })
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const updateProduct = (params: any, id: any) => {

    loader({
      isLoading: true
    })

    const formData = new FormData()
    formData.append('sell_type', params.sellType)
    formData.append('brand_id', params.brand_id)
    formData.append('title', params.title)
    formData.append('price', params.price)
    formData.append('hot', params.hot)
    formData.append('seller', params.seller_pick)
    formData.append('giftsale', params.giftsale)
    formData.append('hot_order', params.hot_order)
    formData.append('pick_order', params.seller_pick_order)
    formData.append('giftsale_order', params.giftsale_order)
    formData.append('is_gift', params.is_gift)
    formData.append('pay_type', params.pay_type)
    formData.append('is_main', params.is_main)
    formData.append('is_use', params.is_use)
    formData.append('file', params.file)
    formData.append('resell_promotion', '')
    formData.append('isbarcode', '0')
    formData.append('id', id)
    formData.append('memo', params.memo)

    defaultService.handleService({
      method: 'post',
      endPoint: '/product/admin/update',
      params: formData,
    }).then(
        response => {
          loader({
            isLoading: false
          })
          // navigate('/brand')
        },
        error => {
          loader({
            isLoading: false
          })
          setAlert({
            title: error.name,
            type: 'alert',
            message: error.message,
            isShow: true
          })
        }
    )
  }

  const updateGiftconState = (params: any) => {
    loader({isLoading: true})
    defaultService.handleService({
      method: 'put',
      endPoint: '/product/giftcon',
      params: params
    }).then(
      response => window.location.reload(),
      error => {
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    ).finally(() => {
      loader({isLoading: false})
    })
  }

  const deleteGiftcon = (params: any) => {
    loader({isLoading: true})
    defaultService.handleService({
      method: 'delete',
      endPoint: '/product/giftcon?item='+params.item.toString()
    }).then(
      response => window.location.reload(),
      error => {
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    ).finally(() => {
      loader({isLoading: false})
    })
  }

  const priceChange = (params: any): Promise<void> => {
    loader({isLoading: true})
    console.log(params)
    return defaultService.handleService({
      endPoint: '/product/priceChange',
      method: 'post',
      params: params
    }).then(
      response => {
        setAlert({
          title: '확인',
          type: 'alert',
          message: '가격이 수정되었습니다.',
          isShow: true
        })
      },
      error => {
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    ).finally(() => {
      loader({isLoading: false})
    })
  }

  return {
    getProductList,
    getGiftConList,
    readBarcode,
    getProduct,
    regisProduct,
    deleteProduct,
    updateProduct,
    regiGiftCon,
    updateGiftconState,
    deleteGiftcon,
    priceChange
  }
}
