import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-self: stretch;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
`

const Section = styled.div`
  display: flex;
  flex: 1;
  gap: 1rem;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 50%;
  align-self: stretch;
  flex-grow: 1;

  @media (max-width: 1008px) {
    max-width: 100%;
  }
`

const Action = styled.div`
  display: flex;
  justify-content: center;
  margin: 2rem 0;
`

export const Style = {
  Container,
  Content,
  Section,
  File,
  Action
}
