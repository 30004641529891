import {atom} from 'recoil'
import {recoilPersist} from 'recoil-persist'

const {persistAtom} = recoilPersist({
  key: 'persist_root',
  storage: localStorage,
})

interface NoticeList {
  pageInfo: {
    page: number
    size: number
    total: number
  },
  data: {
    id: number
    title: string
    order: number
    content: string
    open: number
    createdAt: string
    updatedAt: string
    userId: number
  }[]
}

interface Notice {
  id?: number
  title: string
  content: string
  order?: number
  userId?: number
  open?: number
}

export const tempIdAtom = atom({
  key: 'tempIdAtom',
  default: {},
  effects_UNSTABLE: [persistAtom],
})

export const noticeListAtom = atom<NoticeList>({
  key: 'noticeListAtom',
  default: {
    data: [],
    pageInfo: {
      page: 0,
      size: 0,
      total: 0
    }
  }
})

export const noticeAtom = atom<Notice>({
  key: 'noticeAtom',
  default: {
    id: 0,
    title: '',
    content: '',
    order: 0,
    userId: 0,
    open: 0
  }
})
