import React, {useState, useEffect, useCallback} from 'react'
import {useParams, useNavigate} from 'react-router-dom'

import {useRecoilValue, useSetRecoilState} from 'recoil'
import {PushAction} from "../../store/action/push";
import {alertAtom} from '../../store/recoil/alert'
import {authAtom} from '../../store/recoil/auth'

import InputField from '../../module/input'
import TextArea from '../../module/textarea'
import Button from '../../module/button'
import Location from '../../module/location'
import Checkbox from '../../module/checkbox'

import {Style} from './style/Style'

const Regis = (props: any) => {

  const auth = useRecoilValue(authAtom)
  const setAlert = useSetRecoilState(alertAtom)
  const {sendMessage} = PushAction()
  const [state, setState] = useState<any>({
    content: '',
    submitted: false,
  })

  const handleChange = useCallback((e: any) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  }, [state])

  const handleSubmit = (e: any) => {
    if (e) {
      e.preventDefault()
    }

    const {content} = state

    if (!content) {
      setAlert({
        title: '확인',
        type: 'alert',
        message: '내용을 입력하세요',
        isShow: true
      })
      return
    }

    setState({
      ...state,
      submitted: true
    })

    sendMessage(state.content)
  }


  return (
    <Style.Container>
      <Location location={{name: '푸시 메시지'}}/>
      <Style.Option>
        <Checkbox
          id={'open'}
          label={'전체'}
          checked={true}
          onChange={(e) => console.log("-")}
        />
      </Style.Option>

      {/*<Style.Subject>*/}
      {/*  <InputField*/}
      {/*    useAnimation={true}*/}
      {/*    type={'text'}*/}
      {/*    id="title"*/}
      {/*    name="title"*/}
      {/*    placeholder="제목을 입력하세요"*/}
      {/*    value={state.title}*/}
      {/*    onChange={(e) => handleChange(e)}*/}
      {/*    check={false}*/}
      {/*  />*/}
      {/*</Style.Subject>*/}

      <Style.Content>
        <TextArea
          id={'content'}
          name={'content'}
          placeholder="내용을 입력하세요"
          useAnimation={false}
          value={state.content ? state.content : ''}
          height={'20'}
          onChange={(e) => handleChange(e)}
        />
      </Style.Content>

      <Style.Action>
        <Button
          size={'md'}
          margin={'1rem 1rem 1rem 0'}
          padding={'1rem 2rem'}
          radius={'.5rem'}
          bgcolor={'#3f3b3f'}
          color={'#ffffff'}
          fontSize={'1.4rem'}
          themecolor={'dark-gray'}
          disabled={false}
          onClick={(e) =>
            setAlert({
              title: '확인',
              type: 'confirm',
              message: '발송하시겠습니까?',
              isShow: true,
              action: {
                method: () => {
                  handleSubmit(e)
                }
              }
            })}
        >
          전송
        </Button>

        {/*<Button*/}
        {/*  size={'md'}*/}
        {/*  margin={'1rem 0'}*/}
        {/*  padding={'1rem 2rem'}*/}
        {/*  radius={'.5rem'}*/}
        {/*  bgcolor={'#3f3b3f'}*/}
        {/*  color={'#ffffff'}*/}
        {/*  fontSize={'1.4rem'}*/}
        {/*  themecolor={'white'}*/}
        {/*  disabled={false}*/}
        {/*  onClick={() => navigate('/push/list')}*/}
        {/*>*/}
        {/*  취소*/}
        {/*</Button>*/}
      </Style.Action>
    </Style.Container>
  )
}

export default Regis
